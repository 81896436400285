<!-- START Notification Sidebar-->
<aside [ngClass]="{open: isOpen}" class="notification-sidebar d-none d-sm-none d-md-block" id="notification-sidebar">
  <a (click)="onClose()" class="notification-sidebar-close">
    <i class="ft-x font-medium-3 grey darken-1"></i>
  </a>
  <div [perfectScrollbar] class="side-nav notification-sidebar-content">
    <div class="row">
      <div class="col-12 notification-tab-content">
        <nav #nav="ngbNav" class="nav-tabs" ngbNav>
          <ng-container class="nav-item" ngbNavItem>
            <a class="nav-link" ngbNavLink>Activity</a>
            <ng-template ngbNavContent>
<!--              <div aria-expanded="true" aria-labelledby="base-tab1" class="row tab-pane" id="activity-tab"-->
<!--                   role="tabpanel">-->
<!--                <div class="col-12" id="activity">-->
<!--                  <h5 class="my-2 text-bold-500">System Logs</h5>-->
<!--                  <div class="timeline-left timeline-wrapper mb-3" id="timeline-1">-->
<!--                    <ul class="timeline">-->
<!--                      <li class="timeline-line mt-4"></li>-->
<!--                      <li class="timeline-item">-->
<!--                        <div class="timeline-badge">-->
<!--                          <span class="bg-primary bg-lighten-4" data-placement="right" data-toggle="tooltip"-->
<!--                                title="Portfolio project work">-->
<!--                            <i class="ft-download primary"></i>-->
<!--                          </span>-->
<!--                        </div>-->
<!--                        <div class="activity-list-text">-->
<!--                          <h6 class="mb-1">-->
<!--                            <span>New Update Available</span>-->
<!--                            <span class="float-right grey font-italic font-small-2">1 min ago</span>-->
<!--                          </h6>-->
<!--                          <p class="mt-0 mb-2 font-small-3">Android Pie 9.0.0_r52v availabe (658MB).</p>-->
<!--                          <div class="notification-note">-->
<!--                            <div class="p-1 pl-2">-->
<!--                              <span class="text-bold-500">Download Now!</span>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </li>-->
<!--                      <li class="timeline-item">-->
<!--                        <div class="timeline-badge">-->
<!--                          <span class="bg-primary bg-lighten-4" data-placement="right" data-toggle="tooltip"-->
<!--                                title="Portfolio project work">-->
<!--                            <img alt="avatar" class="avatar" src="assets/img/portrait/small/avatar-s-15.png" width="40">-->
<!--                          </span>-->
<!--                        </div>-->
<!--                        <div class="activity-list-text">-->
<!--                          <h6 class="mb-1">-->
<!--                            <span>Reminder!</span>-->
<!--                            <span class="float-right grey font-italic font-small-2">52 min ago</span>-->
<!--                          </h6>-->
<!--                          <p class="mt-0 mb-2 font-small-3">Your meeting is scheduled with Mr. Derrick Walters at 16:00.-->
<!--                          </p>-->
<!--                          <div class="notification-note">-->
<!--                            <div class="p-1 pl-2">-->
<!--                              <span class="text-bold-500">Snooze</span>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </li>-->
<!--                      <li class="timeline-item">-->
<!--                        <div class="timeline-badge">-->
<!--                          <span class="bg-primary bg-lighten-4" data-placement="right" data-toggle="tooltip"-->
<!--                                title="Portfolio project work">-->
<!--                            <img alt="avatar" class="avatar" src="assets/img/portrait/small/avatar-s-16.png" width="40">-->
<!--                          </span>-->
<!--                        </div>-->
<!--                        <div class="activity-list-text">-->
<!--                          <h6 class="mb-1">-->
<!--                            <span>Recieved a File</span>-->
<!--                            <span class="float-right grey font-italic font-small-2">4 hours ago</span>-->
<!--                          </h6>-->
<!--                          <p class="mt-0 mb-2 font-small-3">Christina Rogers sent you a file for the next conference.-->
<!--                          </p>-->
<!--                          <div class="notification-note">-->
<!--                            <div class="p-1 pl-2">-->
<!--                              <img alt="icon" src="assets/img/icons/sketch-mac-icon.png" width="20">-->
<!--                              <span class="text-bold-500 ml-2">Diamond.sketch</span>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </li>-->
<!--                      <li class="timeline-item">-->
<!--                        <div class="timeline-badge">-->
<!--                          <span class="bg-primary bg-lighten-4" data-placement="right" data-toggle="tooltip"-->
<!--                                title="Portfolio project work">-->
<!--                            <i class="ft-mic primary"></i>-->
<!--                          </span>-->
<!--                        </div>-->
<!--                        <div class="activity-list-text">-->
<!--                          <h6 class="mb-1">-->
<!--                            <span>Voice Message</span>-->
<!--                            <span class="float-right grey font-italic font-small-2">10 hours ago</span>-->
<!--                          </h6>-->
<!--                          <p class="mt-0 mb-2 font-small-3">Natalya Parker sent you a voice message.</p>-->
<!--                          <div class="notification-note">-->
<!--                            <div class="p-1 pl-2">-->
<!--                              <span class="text-bold-500">Listen</span>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </li>-->
<!--                      <li class="timeline-item">-->
<!--                        <div class="timeline-badge">-->
<!--                          <span class="bg-primary bg-lighten-4" data-placement="right" data-toggle="tooltip"-->
<!--                                title="Portfolio project work">-->
<!--                            <i class="ft-cloud-drizzle primary"></i>-->
<!--                          </span>-->
<!--                        </div>-->
<!--                        <div class="activity-list-text">-->
<!--                          <h6 class="mb-1">-->
<!--                            <span>Weather Update</span>-->
<!--                            <span class="float-right grey font-italic font-small-2">Yesterday</span>-->
<!--                          </h6>-->
<!--                          <p class="mt-0 mb-2 font-small-3">Hi John! It is a rainy day with 16&deg;C.</p>-->
<!--                        </div>-->
<!--                      </li>-->
<!--                    </ul>-->
<!--                  </div>-->
<!--                  <h5 class="my-2 text-bold-500">Applications Logs</h5>-->
<!--                  <div class="timeline-left timeline-wrapper" id="timeline-2">-->
<!--                    <ul class="timeline">-->
<!--                      <li class="timeline-line mt-4"></li>-->
<!--                      <li class="timeline-item">-->
<!--                        <div class="timeline-badge">-->
<!--                          <span class="bg-primary bg-lighten-4" data-placement="right" data-toggle="tooltip"-->
<!--                                title="Portfolio project work">-->
<!--                            <img alt="avatar" class="avatar" src="assets/img/portrait/small/avatar-s-26.png" width="40">-->
<!--                          </span>-->
<!--                        </div>-->
<!--                        <div class="activity-list-text">-->
<!--                          <h6 class="mb-1">-->
<!--                            <span>Gmail</span>-->
<!--                            <span class="float-right grey font-italic font-small-2">Just now</span>-->
<!--                          </h6>-->
<!--                          <p class="mt-0 mb-2 font-small-3">Victoria Hampton sent you a mail and has a file attachment-->
<!--                            with it.</p>-->
<!--                          <div class="notification-note">-->
<!--                            <div class="p-1 pl-2">-->
<!--                              <img alt="pdf icon" src="assets/img/icons/pdf.png" width="20">-->
<!--                              <span class="text-bold-500 ml-2">Register.pdf</span>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </li>-->
<!--                      <li class="timeline-item">-->
<!--                        <div class="timeline-badge">-->
<!--                          <span class="bg-primary bg-lighten-4" data-placement="right" data-toggle="tooltip"-->
<!--                                title="Portfolio project work">-->
<!--                            <i class="ft-droplet primary"></i>-->
<!--                          </span>-->
<!--                        </div>-->
<!--                        <div class="activity-list-text">-->
<!--                          <h6 class="mb-1">-->
<!--                            <span>MakeMyTrip</span>-->
<!--                            <span class="float-right grey font-italic font-small-2">7 hours ago</span>-->
<!--                          </h6>-->
<!--                          <p class="mt-0 mb-2 font-small-3">Your next flight for San Francisco will be on 24th March.-->
<!--                          </p>-->
<!--                          <div class="notification-note">-->
<!--                            <div class="p-1 pl-2">-->
<!--                              <span class="text-bold-500">Important</span>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </li>-->
<!--                      <li class="timeline-item">-->
<!--                        <div class="timeline-badge">-->
<!--                          <span class="bg-primary bg-lighten-4" data-placement="right" data-toggle="tooltip"-->
<!--                                title="Portfolio project work">-->
<!--                            <img alt="avatar" class="avatar" src="assets/img/portrait/small/avatar-s-23.png" width="40">-->
<!--                          </span>-->
<!--                        </div>-->
<!--                        <div class="activity-list-text">-->
<!--                          <h6 class="mb-1">-->
<!--                            <span>CNN</span>-->
<!--                            <span class="float-right grey font-italic font-small-2">16 hours ago</span>-->
<!--                          </h6>-->
<!--                          <p class="mt-0 mb-2 font-small-3">U.S. investigating report says email account linked to CIA-->
<!--                            Director was hacked.</p>-->
<!--                          <div class="notification-note">-->
<!--                            <div class="p-1 pl-2">-->
<!--                              <span class="text-bold-500">Read full article</span>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </li>-->
<!--                      <li class="timeline-item">-->
<!--                        <div class="timeline-badge">-->
<!--                          <span class="bg-primary bg-lighten-4" data-placement="right" data-toggle="tooltip"-->
<!--                                title="Portfolio project work">-->
<!--                            <i class="ft-map primary"></i>-->
<!--                          </span>-->
<!--                        </div>-->
<!--                        <div class="activity-list-text">-->
<!--                          <h6 class="mb-1">-->
<!--                            <span>Maps</span>-->
<!--                            <span class="float-right grey font-italic font-small-2">Yesterday</span>-->
<!--                          </h6>-->
<!--                          <p class="mt-0 mb-2 font-small-3">You visited Walmart Supercenter in Chicago.</p>-->
<!--                          <div class="notification-note">-->
<!--                            <div class="p-1 pl-2">-->
<!--                              <span class="text-bold-500">Write a Review!</span>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </li>-->
<!--                      <li class="timeline-item">-->
<!--                        <div class="timeline-badge">-->
<!--                          <span class="bg-primary bg-lighten-4" data-placement="right" data-toggle="tooltip"-->
<!--                                title="Portfolio project work">-->
<!--                            <i class="ft-package primary"></i>-->
<!--                          </span>-->
<!--                        </div>-->
<!--                        <div class="activity-list-text">-->
<!--                          <h6 class="mb-1">-->
<!--                            <span>Updates Available</span>-->
<!--                            <span class="float-right grey font-italic font-small-2">2 days ago</span>-->
<!--                          </h6>-->
<!--                          <p class="mt-0 mb-2 font-small-3">19 app updates found.</p>-->
<!--                        </div>-->
<!--                      </li>-->
<!--                    </ul>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
            </ng-template>
          </ng-container>
          <ng-container class="nav-item" ngbNavItem>
            <a class="nav-link" ngbNavLink>Settings</a>
            <ng-template ngbNavContent>
              <div aria-labelledby="base-tab2" class="row tab-pane" id="settings-tab">
                <div class="col-12" id="settings">
                  <h5 class="mt-2 mb-3">General Settings</h5>
<!--                  <ul class="list-unstyled mb-0 mx-2">-->
<!--                    <li class="mb-3">-->
<!--                      <div class="mb-1">-->
<!--                        <span class="text-bold-500">Notifications</span>-->
<!--                        <div class="float-right">-->
<!--                          <div class="custom-switch">-->
<!--                            <input class="custom-control-input" id="noti-s-switch-1" type="checkbox">-->
<!--                            <label class="custom-control-label" for="noti-s-switch-1"></label>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <p class="font-small-3 m-0">Use switches when looking for yes or no answers.</p>-->
<!--                    </li>-->
<!--                    <li class="mb-3">-->
<!--                      <div class="mb-1"><span class="text-bold-500">Show recent activity</span>-->
<!--                        <div class="float-right">-->
<!--                          <div class="checkbox">-->
<!--                            <input checked="checked" id="noti-s-checkbox-1" type="checkbox">-->
<!--                            <label for="noti-s-checkbox-1"></label>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <p class="font-small-3 m-0">The "for" attribute is necessary to bind checkbox with the input.</p>-->
<!--                    </li>-->
<!--                    <li class="mb-3">-->
<!--                      <div class="mb-1"><span class="text-bold-500">Product Update</span>-->
<!--                        <div class="float-right">-->
<!--                          <div class="custom-switch">-->
<!--                            <input checked="checked" class="custom-control-input" id="noti-s-switch-4" type="checkbox">-->
<!--                            <label class="custom-control-label" for="noti-s-switch-4"></label>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <p class="font-small-3 m-0">Message and mail me on weekly product updates.</p>-->
<!--                    </li>-->
<!--                    <li class="mb-3">-->
<!--                      <div class="mb-1">-->
<!--                        <span class="text-bold-500">Email on Follow</span>-->
<!--                        <div class="float-right">-->
<!--                          <div class="custom-switch">-->
<!--                            <input class="custom-control-input" id="noti-s-switch-3" type="checkbox">-->
<!--                            <label class="custom-control-label" for="noti-s-switch-3"></label>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <p class="font-small-3 m-0">Mail me when someone follows me.</p>-->
<!--                    </li>-->
<!--                    <li class="mb-3">-->
<!--                      <div class="mb-1">-->
<!--                        <span class="text-bold-500">Announcements</span>-->
<!--                        <div class="float-right">-->
<!--                          <div class="checkbox">-->
<!--                            <input checked="checked" id="noti-s-checkbox-2" type="checkbox">-->
<!--                            <label for="noti-s-checkbox-2"></label>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <p class="font-small-3 m-0">Receive all the news and announcements from my clients.</p>-->
<!--                    </li>-->
<!--                    <li class="mb-3">-->
<!--                      <div class="mb-1">-->
<!--                        <span class="text-bold-500">Date and Time</span>-->
<!--                        <div class="float-right">-->
<!--                          <div class="checkbox">-->
<!--                            <input id="noti-s-checkbox-3" type="checkbox">-->
<!--                            <label for="noti-s-checkbox-3"></label>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <p class="font-small-3 m-0">Show date and time on top of every page.</p>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <div class="mb-1">-->
<!--                        <span class="text-bold-500">Email on Comments</span>-->
<!--                        <div class="float-right">-->
<!--                          <div class="custom-switch">-->
<!--                            <input checked="checked" class="custom-control-input" id="noti-s-switch-2" type="checkbox">-->
<!--                            <label class="custom-control-label" for="noti-s-switch-2"></label>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <p class="font-small-3 m-0">Mail me when someone comments on my article.</p>-->
<!--                    </li>-->
<!--                  </ul>-->
                </div>
              </div>
            </ng-template>
          </ng-container>
        </nav>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>
</aside>
<!-- END Notification Sidebar-->
