<div class="inf-card card box-shadow-2 new-element">
    <div class="inf-card__container" (click)="action.emit()">
        <div class="inf-card__content fl-column fl-align-stretch">
            <div class="inf-card__header">
                <img src="../../../../assets/img/service_all.png">
                <!--<span style="font-size: 90px;margin-top: 21px;" class="fa fa-bed"></span>-->
            </div>
            <div class="inf-card__text fl-flex-auto fl-column fl-align-center fl-justify-center">
                <ng-content></ng-content>
            </div>
            <div class="inf-card__content__icon-add">
                <span style="margin-top: 4px" class="fa fa-plus"></span></div>
        </div>
    </div>
</div>
