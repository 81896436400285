import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {TouristicServiceComplete} from 'app/entities/touristic-service/touristic-service-complete.model';

@Injectable({providedIn: 'root'})
export class CurrentTouristicServiceService {
  private touristicService?: TouristicServiceComplete | null;
  private touristicService$: Subject<TouristicServiceComplete | null> = new Subject<TouristicServiceComplete | null>();
  private _tierPublicId?: string;
  private _tierCategory: any;
  private _servicePublicId?: string;
  private _status?: string;

  getTouristicService() {
    return this.touristicService;
  }

  setTouristicService(touristicService: TouristicServiceComplete | null) {
    this.touristicService = touristicService;
    this.touristicService$.next(touristicService);
  }

  getTierPublicId(): string {
    return this._tierPublicId || '';
  }

  getTierCategory(): any {
    return this._tierCategory;
  }

  setTierPublicId(value: string) {
    this._tierPublicId = value;
  }

  setTierCategory(value: any) {
    this._tierCategory = value;
  }

  getServicePublicId(): string {
    return this._tierPublicId || '';
  }

  setServicePublicId(value: string) {
    this._servicePublicId = value;
  }

  setServiceStatus(value: string) {
    this._status = value;
  }

  /**
   * same as setTier, but without triggering observable
   */
  updateTouristicService(touristicService: any) {
    this.touristicService = touristicService;
  }

  getTouristicServiceObservable(): Observable<TouristicServiceComplete | null> {
    return this.touristicService$.asObservable();
  }
}
