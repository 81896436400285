import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'margeMode',
})
export class MargeModePipe implements PipeTransform {
  transform(marge: string) {
    return marge === 'percent' ? '%' : '$';
  }
}
