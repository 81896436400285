<form (ngSubmit)="confirmValidate()" name="deleteForm">
  <div class="modal-header">
    <div class="fl-popin__title">{{title}}</div>
    <button (click)="clear()" aria-hidden="true" class="close" data-dismiss="modal" type="button">&times;
    </button>
  </div>
  <div class="modal-body">
    <deal-alert-error></deal-alert-error>
    <p>{{body! | translate:{'element': elementName} }}</p>

    <div *ngIf="commentaire" class="form-group col-md-12">
      <label class="form-control-label" for="commentaire">Comment</label>
      <textarea [(ngModel)]="comment" class="form-control" id="commentaire" name="commentaire"
                placeholder="your comment" type="text"></textarea>
    </div>

    <div *ngIf="!showbutton">
      <a (click)="gotToRegister()">Create an Account</a>
    </div>
    <div *ngIf="!showbutton">
      <a (click)="gotToLogin()">Login</a>
    </div>
  </div>
  <div *ngIf="showbutton" class="modal-footer">
    <button (click)="clear()" mat-raised-button type="reset">
      <i class="mdi mdi-cancel"></i>&nbsp;
      <span translate="entity.action.cancel"></span>
    </button>
    <button color="primary" mat-raised-button type="submit">
      <i class="mdi mdi-content-save"></i>&nbsp;
      <span [translate]="action"></span>
    </button>
  </div>
</form>
