import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';


import {TranslateService} from '@ngx-translate/core';
import {PaginationConfig} from 'app/config/uib-pagination.config';
import {ITEMS_PER_PAGE} from 'app/config/pagination.constants';
import {AccountService} from 'app/core/auth/account.service';
import {TierService} from 'app/shared/services/tier.service';
import {TouristicService} from 'app/entities/touristic-service/touristic-service.model';
import {TouristicServiceService} from 'app/entities/touristic-service/touristic-service.service';
import {CurrentTouristicServiceService} from 'app/entities/touristic-service/current-touristic-service.service';
import {CLASSES} from 'app/shared/constants/class.constants';
import {Observable} from 'rxjs';
import {FormBuilder} from '@angular/forms';
import {CountryService} from 'app/shared/services/country.service';
import {ServiceTypeService} from 'app/shared/services/service-type.service';
import {ServiceTypeGroup} from 'app/shared/model/service-type.model';
import {TOURISTIC_SERVICE_STATUS} from 'app/shared/constants/service-status.constants';
import {Account} from 'app/core/user/account.model';
import {ParseLinks} from "../../core/util/parse-links.service";
import {AlertService} from "../../core/util/alert.service";
import {EventManager} from "../../core/util/event-manager.service";
import {map} from "rxjs/operators";

@Component({
  selector: 'jhi-touristic-service-cards',
  styleUrls: ['../touristic-service/touristic-service.scss', '../touristic-service/subbaSidebar.scss'],
  templateUrl: './touristic-service-cards.component.html',
})
export class TouristicServiceCardsComponent implements OnInit, OnDestroy {
  currentAccount: any;
  error: any;
  @Input()
  buttonNew?: boolean;
  success: any;
  eventSubscriber?: Subscription;
  routeData: any;
  links: any;
  totalItems: any;
  queryCount: any;
  itemsPerPage: any;
  page: any;
  predicate: any;
  previousPage: any;
  reverse: any;
  showCreateTier?: boolean;
  pending?: boolean;
  active?: boolean;
  @Input()
  start?: any[][];
  @Input()
  touristicServices?: TouristicService[];
  @Output() action = new EventEmitter();

  classes = CLASSES;
  serviceFilter: any = {};
  state$?: Observable<any>;
  editForm = this.fb.group({
    country: [null],
    serviceType: [null],
    status: [null],
  });
  serviceTypes: ServiceTypeGroup[] = [];

  countries: any[] = [];
  statuss = TOURISTIC_SERVICE_STATUS;

  constructor(
    private touristicServiceService: TouristicServiceService,
    private currentTouristicServiceService: CurrentTouristicServiceService,
    private parseLinks: ParseLinks,
    private jhiAlertService: AlertService,
    private fb: FormBuilder,
    private principal: AccountService,
    protected serviceTypeService: ServiceTypeService,
    private countryService: CountryService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private tierService: TierService,
    private router: Router,
    private eventManager: EventManager,
    private paginationConfig: PaginationConfig
  ) {
    this.translate.setDefaultLang('en');
    this.itemsPerPage = ITEMS_PER_PAGE;
    this.page = 1;
    this.previousPage = 1;
    this.reverse = 'acs';
    this.predicate = '';
  }

  showTouristicService(touristicService: TouristicService) {
    this.router.navigate(['/page/ref-tier/' + touristicService.tierPublicId + '/touristic-service/' + touristicService.publicId]);
  }

  loadPage(page: number) {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.transition();
    }
  }

  transition() {
    this.router.navigate(['/touristic-service'], {
      queryParams: {
        page: this.page,
        size: this.itemsPerPage,
        sort: this.predicate + ',' + (this.reverse ? 'asc' : 'desc'),
      },
    });
  }

  clear() {
    this.page = 1;
    this.router.navigate([
      '/touristic-service',
      {
        page: this.page,
        sort: this.predicate + ',' + (this.reverse ? 'asc' : 'desc'),
      },
    ]);
  }

  ngOnInit() {
    this.serviceFilter = {};
    this.countryService.queryCountryName().subscribe(res => (this.countries = res.body));
    this.serviceTypeService.getAll().subscribe(value => {
      this.serviceTypes = value.body || [];
    });
    this.state$ = this.activatedRoute.paramMap.pipe(map(() => window.history.state));
    this.state$.subscribe(res => {
      this.pending = res.pending;
      this.active = res.active;
      if (this.pending) {
        this.serviceFilter = {status: 'DRAFT', complete: true};
      }
      if (this.active) {
        this.serviceFilter = {status: 'ACTIVE'};
      }
    });
    this.principal.identity().subscribe((account: Account | null) => {
      this.currentAccount = account!;
    });
    this.showCreateTier = true;
  }

  ngOnDestroy() {
    if (this.eventSubscriber) {
      this.eventManager.destroy(this.eventSubscriber);
    }
  }

  trackId(index: number, item: TouristicService) {
    return item.id;
  }

  sort() {
    const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
    if (this.predicate !== 'id') {
      result.push('id');
    }
    return result;
  }

  arrayOne(n: number): any[] {
    return Array(n);
  }

  createTOuristicService($envent: any): void {
    this.action.emit($envent);
  }

  private onError(error: string) {
    this.jhiAlertService.error(error, null, '');
    this.eventManager.broadcast({name: 'ImgLoading', content: false});
  }

}
