// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  url:'localhost',
  configFile: 'assets/config/config.dev.json',
  KEYCLOAK_URL: window['env'].KEYCLOAK_URL,
  KEYCLOAK_REALM: window['env'].KEYCLOAK_REALM,
  KEYCLOAK_CLIENT_ID: window['env'].KEYCLOAK_CLIENT_ID,
  GOOGLE_SITE_KEY: window['env'].GOOGLE_SITE_KEY,
};
