import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'findCurrencyFromKey'})
export class FindCurrencyFromKeyPipe implements PipeTransform {
    private currencies: { [key: string]: { name: string } } = {
        EUR: {name: 'Euro'},
        TND: {name: 'Dinar Tunisian'},
        USD: {name: 'U.S. Dollar'},
        // jhipster-needle-i18n-language-key-pipe - JHipster will add/remove languages in this object
    };

    transform(lang: string): string {
        return this.currencies[lang].name;
    }

}

@Pipe({name: 'findFlagFromCurrencyKey'})
export class FindFlagFromCurrencyKey implements PipeTransform {
    private currencies: { [key: string]: { flag: string } } = {
        EUR: {flag: '€'},
        TND: {flag: 'DT'},
        USD: {flag: '$'},
        // jhipster-needle-i18n-language-key-pipe - JHipster will add/remove languages in this object
    };

    transform(lang: string): string {
        return this.currencies[lang]?this.currencies[lang].flag:"";
    }

}
