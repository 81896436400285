import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LayoutService} from '../services/layout.service';
import {Subject, Subscription} from 'rxjs';
import {ConfigService} from '../services/config.service';
import {FormControl} from '@angular/forms';
import {LISTITEMS} from '../data/template-search';
import {Router} from '@angular/router';
import {LoginService} from "../../login/login.service";
import {LANGUAGES} from "../../config/language.constants";
import {SessionStorageService} from "ngx-webstorage";
import {AuthServerProvider} from "../../core/auth/auth-jwt.service";
import {CurrentCurrencyService} from "../services/current-currency.service";
import {CURRENCYS_NEW} from "../currency/currency.constants";
import {AccountObservableService} from "../services/accountObservable.service";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  currentLang = "en";
  selectedLanguageText = "English";
  selectedLanguageFlag = "./assets/img/flags/us.png";
  toggleClass = "ft-maximize";
  placement = "bottom-right";
  logoUrl = 'assets/img/logo.png';
  menuPosition = 'Side';
  isSmallScreen = false;
  searchOpenClass = "";
  transparentBGClass = "";
  hideSidebar = true;
  public isCollapsed = true;
  layoutSub: Subscription;
  configSub: Subscription;
  @ViewChild('search') searchElement: ElementRef;
  @ViewChildren('searchResults') searchResults: QueryList<any>;
  @Output()
  toggleHideSidebar = new EventEmitter<Object>();
  @Output()
  seachTextEmpty = new EventEmitter<boolean>();
  listItems = [];
  control = new FormControl();
  public config: any = {};
  protected innerWidth: any;
  languages = LANGUAGES;
  currencies = CURRENCYS_NEW;
  languageKey = '';
  email = '';
  userPhoto: string;
  profile: any;
  userPhotoContentType: string;
  currencyKey = 'EUR';
  isNavbarCollapsed = true;
  protected _onDestroy = new Subject<boolean>();

  constructor(public translate: TranslateService,
              private layoutService: LayoutService,
              private sessionStorage: SessionStorageService,
              private authServerProvider: AuthServerProvider,
              private loginService: LoginService,
              private router: Router,
              private accountObservableService: AccountObservableService,
              private currentCurrencyService: CurrentCurrencyService,
              private configService: ConfigService, private cdr: ChangeDetectorRef) {

    const browserLang: string = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : "en");
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;

    this.layoutSub = layoutService.toggleSidebar$.subscribe(
      isShow => {
        this.hideSidebar = !isShow;
      });

  }

  ngOnInit() {
    this.listItems = LISTITEMS;

    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }
    this.languageKey = this.sessionStorage.retrieve('locale');
    if (!this.languageKey) {
      this.languageKey = 'en';
    }
    this.currencyKey = this.sessionStorage.retrieve('currency');
    if (!this.currencyKey) {
      this.currencyKey = 'EUR';
      this.sessionStorage.store('currency', this.currencyKey);
      this.currentCurrencyService.setCurrency("EUR");
    }
    this.profile = this.authServerProvider.getProfileData();
    this.userPhoto = this.authServerProvider.getUserPhoto();
    this.userPhotoContentType = this.authServerProvider.getUserPhotoContentType();
    this.accountObservableService.getCurrentProfile().pipe(takeUntil(this._onDestroy)).subscribe((profile: any) => {
      this.userPhoto = profile.userPhoto;
      this.profile = profile;
    })
  }

  changeLanguage(languageKey: string): void {
    this.sessionStorage.store('locale', languageKey);
    this.translate.use(languageKey);
    this.languageKey = languageKey;
  }

  changeCurrency(currencyKey: string): void {
    this.sessionStorage.store('currency', currencyKey);
    this.currencyKey = currencyKey;
    this.currentCurrencyService.setCurrency(this.currencyKey);
  }

  ngAfterViewInit() {

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    })
  }

  collapseNavbar(): void {
    this.isNavbarCollapsed = true;
  }

  ngOnDestroy(): void {
    this._onDestroy.next(true);
    this._onDestroy.complete();

    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }
  }

  loadLayout() {

    if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() !== "") {
      this.menuPosition = this.config.layout.menuPosition;
    }

    if (this.config.layout.variant === "Light") {
      this.logoUrl = 'assets/img/logo-dark.png';
    } else {
      this.logoUrl = 'assets/img/logo.png';
    }

    if (this.config.layout.variant === "Transparent") {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    } else {
      this.transparentBGClass = "";
    }

  }

  onSearchKey(event: any) {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.add('first-active-item');
    }

    if (event.target.value === "") {
      this.seachTextEmpty.emit(true);
    } else {
      this.seachTextEmpty.emit(false);
    }
  }

  removeActiveClass() {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.remove('first-active-item');
    }
  }

  onEscEvent() {
    this.control.setValue("");
    this.searchOpenClass = '';
    this.seachTextEmpty.emit(true);
  }

  onEnter() {
    if (this.searchResults && this.searchResults.length > 0) {
      const url = this.searchResults.first.url;
      if (url && url !== '') {
        this.control.setValue("");
        this.searchOpenClass = '';
        this.router.navigate([url]);
        this.seachTextEmpty.emit(true);
      }
    }
  }

  redirectTo(value) {
    this.router.navigate([value]);
    this.seachTextEmpty.emit(true);
  }


  ChangeLanguage(language: string) {
    this.translate.use(language);

    if (language === 'en') {
      this.selectedLanguageText = "English";
      this.selectedLanguageFlag = "./assets/img/flags/us.png";
    } else if (language === 'es') {
      this.selectedLanguageText = "Spanish";
      this.selectedLanguageFlag = "./assets/img/flags/es.png";
    } else if (language === 'pt') {
      this.selectedLanguageText = "Portuguese";
      this.selectedLanguageFlag = "./assets/img/flags/pt.png";
    } else if (language === 'de') {
      this.selectedLanguageText = "German";
      this.selectedLanguageFlag = "./assets/img/flags/de.png";
    }
  }

  ToggleClass() {
    if (this.toggleClass === "ft-maximize") {
      this.toggleClass = "ft-minimize";
    } else {
      this.toggleClass = "ft-maximize";
    }
  }

  toggleSearchOpenClass(display) {
    this.control.setValue("");
    if (display) {
      this.searchOpenClass = 'open';
      setTimeout(() => {
        this.searchElement.nativeElement.focus();
      }, 0);
    } else {
      this.searchOpenClass = '';
    }
    this.seachTextEmpty.emit(true);


  }


  toggleNotificationSidebar() {
    this.layoutService.toggleNotificationSidebar(true);
  }

  logout() {
    this.loginService.logout();
  }

  toggleSidebar() {
    this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
  }
}
