<div
  [ngClass]="{'navbar-sticky': menuPosition === 'Top' && config?.layout.navbar.type === 'Static' , 'navbar-fixed': menuPosition === 'Top' && config?.layout.navbar.type === 'Fixed'}"
  class="header-navbar navbar-expand-sm navbar navbar-horizontal navbar-fixed navbar-light navbar-shadow menu-border navbar-brand-center  {{transparentBGClass}}" data-menu="menu-wrapper" data-nav="brand-center"
  role="navigation">
  <!-- Horizontal menu content-->
  <div class="navbar-container main-menu-content center-layout" data-menu="menu-container">
    <ul appTopMenuDropdown class="navigation-main nav navbar-nav" data-menu="menu-navigation" id="main-menu-navigation">
      <!-- First level menu -->
      <li *ngFor="let menuItem of menuItems" [attr.data-menu]="menuItem.class === 'dropdown nav-item' ? 'dropdown' : null" [ngClass]="[menuItem.class]" [parent]="menuItem.title"
          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="menuItem.submenu.length != 0 ? 'active' : 'active'"
          appTopMenulink level="{{level + 1}}">

        <a *ngIf="menuItem.submenu.length > 0 && !menuItem.isExternalLink" appTopMenuAnchorToggle class="dropdown-toggle nav-link d-flex align-items-center"
           data-toggle="dropdown">
          <i [ngClass]="[menuItem.icon]"></i>
          <span>{{menuItem.title | translate }}</span>
        </a>
        <a *ngIf="menuItem.submenu.length === 0 && !menuItem.isExternalLink" appTopMenuAnchorToggle class="nav-link d-flex align-items-center"
           data-toggle="dropdown" routerLink="{{menuItem.path}}">
          <i [ngClass]="[menuItem.icon]"></i>
          <span>{{menuItem.title | translate }}</span>
        </a>
        <a *ngIf="menuItem.isExternalLink" [href]="[menuItem.path]" class="nav-link d-flex align-items-center" data-toggle="dropdown"
           target="_blank">
          <i [ngClass]="[menuItem.icon]"></i>
          <span>{{menuItem.title | translate }}</span>
        </a>

        <!-- Second level menu -->
        <ul *ngIf="menuItem.submenu.length > 0" appTopMenuDropdown class="dropdown-menu">
          <li *ngFor="let menuSubItem of menuItem.submenu" [attr.data-menu]="menuSubItem.class === 'dropdown-item' ? '' : 'dropdown-submenu'"
              [ngClass]="menuSubItem.class === 'dropdown-item' ? '' : 'has-sub dropdown dropdown-submenu'" [parent]="menuItem.title" [routerLinkActive]="menuSubItem.submenu.length != 0 ? 'active' : 'active'"
              appTopMenulink
              level="{{level + 2}}">

            <a *ngIf="menuSubItem.submenu.length > 0 && !menuSubItem.isExternalLink" [ngClass]="'dropdown-toggle dropdown-item d-flex align-items-center'"
               appTopMenuAnchorToggle>
              <i [ngClass]="[menuSubItem.icon]"></i>
              <span>{{menuSubItem.title | translate }}</span>
            </a>
            <a *ngIf="menuSubItem.submenu.length === 0 && !menuSubItem.isExternalLink" [ngClass]="'dropdown-item d-flex align-items-center'" appTopMenuAnchorToggle
               routerLink="{{menuSubItem.path}}">
              <i [ngClass]="[menuSubItem.icon]"></i>
              <span>{{menuSubItem.title | translate }}</span>
            </a>
            <a *ngIf="menuSubItem.isExternalLink" [href]="[menuSubItem.path]"
               [ngClass]="'dropdown-item d-flex align-items-center'">
              <i [ngClass]="[menuSubItem.icon]"></i>
              <span>{{menuSubItem.title | translate }}</span>
            </a>

            <!-- Third level menu -->
            <ul *ngIf="menuSubItem.submenu.length > 0" appTopMenuDropdown class="dropdown-menu">
              <li *ngFor="let menuSubsubItem of menuSubItem.submenu" [parent]="menuItem.title" [routerLinkActiveOptions]="{exact: true}" appTopMenulink
                  data-menu="" data-toggle="dropdown" level="{{level + 3}}"
                  routerLinkActive="active">
                <a *ngIf="!menuSubsubItem.isExternalLink; else externalSubSubLinkBlock" [ngClass]="'dropdown-item d-flex align-items-center'" [routerLink]="[menuSubsubItem.path]"
                   appTopMenuAnchorToggle
                   data-toggle="dropdown">
                  <i [ngClass]="[menuSubsubItem.icon]"></i>
                  <span class="">{{menuSubsubItem.title}}</span>
                </a>
                <ng-template #externalSubSubLinkBlock>
                  <a [href]="[menuSubsubItem.path]" [ngClass]="'dropdown-item d-flex align-items-center'"
                     data-toggle="dropdown" target="_blank">
                    <i [ngClass]="[menuSubsubItem.icon]"></i>
                    <span class="">{{menuSubsubItem.title}}</span>
                  </a>
                </ng-template>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
