import { Injectable } from '@angular/core';
import { SERVER_SECURITY_API_URL } from 'app/app.constants';
import { HttpClient, HttpResponse } from '@angular/common/http';
 import { Observable } from 'rxjs';
import {RouteInfo} from "./vertical-menu.metadata";

@Injectable({
  providedIn: 'root',
})
export class PageService {
  private resourceUrl = SERVER_SECURITY_API_URL + 'api/pages';

  constructor(private http: HttpClient) {}

  getAllPages(roleId: string): Observable<HttpResponse<RouteInfo[]>> {
    return this.http.get<RouteInfo[]>(`${this.resourceUrl}/${roleId}`, { observe: 'response' });
  }
}
