<div class="md-block ">
  <div class="row ">
    <deal-card-new-element (action)="createTOuristicService($event)" *ngIf="buttonNew" [icon]="'fa-bed'"
                           class="col-lg-3 col-md-6 col-sm-12 fl-brands__item">
      <div class="fl-brands__new-brand">{{'touristicService.NEW_SERVICE' | translate}}</div>
    </deal-card-new-element>
    <div *ngFor="let touristicService of touristicServices ;let t = index;" class="col-lg-3 col-md-6 col-sm-12">
      <div class="card box-shadow-2">
        <div [class]="'ribbon ribbon-'+touristicService.serviceType">
          <span>{{touristicService.serviceType}}</span>
        </div>
        <div class="ribbon-new">
                    <span [class]="touristicService.status==='ACTIVE'?'ribbon3 ribbon3-active' :(touristicService.status==='DRAFT' && touristicService.complete)
                          ?'ribbon3 ribbon3-improve':'ribbon3 ribbon3-complete'">
                        <span>{{touristicService.status === 'ACTIVE' ? 'ACTIVE' : (touristicService.status === 'DRAFT' && touristicService.complete) ? 'WAITING FOR APPROVE' : 'WAITING FOR COMPLETION' }}</span>
                    </span>
        </div>
        <div [class]="'card-header card-header_'+touristicService.serviceType">
          <img *ngIf="!touristicService.logoService" alt="Card image cap" src="../../../assets/img/moorishHome.png">
          <img *ngIf="touristicService.logoService" [src]="'data:' + touristicService.logoServiceContentType + ';base64,' + touristicService.logoService"
               alt="Card image cap" class="img-touristic">
        </div>
        <div class="card-body" style="background-color: white;    margin-top: 30px;">
          <div class="card-block">
            <p *ngIf="touristicService.name">{{touristicService.name}}</p>
            <p *ngIf="!touristicService.name">----</p>
            <p *ngIf="touristicService.code">{{touristicService.code}}</p>
            <p *ngIf="!touristicService.code"> ----</p>
            <p *ngIf="touristicService.webSite">{{touristicService.webSite}}</p>
            <p *ngIf="!touristicService.webSite">----</p>
            <div *ngIf="touristicService.star" class="row" style="margin: auto;justify-content: center;">
              <div *ngFor="let s of start[t]" style="margin-right: 10px;">
                <i *ngIf="s.name==='t'" aria-hidden="true" class=" mdi mdi-star"></i>
                <i *ngIf="s.name==='f'" aria-hidden="true" class=" mdi mdi-star-outline" style="color: #ececec"></i>
              </div>
            </div>
            <div *ngIf="!touristicService.star" class="row" style="    margin: auto;     justify-content: center;">
              <i aria-hidden="true" class=" mdi mdi-star-outline" style="margin-right: 10px;color: #ececec"></i>
              <i aria-hidden="true" class=" mdi mdi-star-outline" style="margin-right: 10px;color: #ececec"></i>
              <i aria-hidden="true" class=" mdi mdi-star-outline" style="margin-right: 10px;color: #ececec"></i>
              <i aria-hidden="true" class=" mdi mdi-star-outline" style="margin-right: 10px;color: #ececec"></i>
              <i aria-hidden="true" class=" mdi mdi-star-outline" style="margin-right: 10px;color: #ececec"></i>
            </div>
          </div>
        </div>
        <div class="card-footer border-top-blue-grey border-top-lighten-5 text-muted">
          <button (click)="showTouristicService(touristicService)" [ngStyle]="{'background-color':(touristicService.serviceType==='HOTEL'?'rgba(31, 129, 46, 0.89)':touristicService.serviceType==='VILLA'?
                            'rgb(155 5 157)':touristicService.serviceType==='APARTMENT'?'rgb(173 62 6)':touristicService.serviceType==='COTTAGE'?'rgb(28 140 211)':
                            touristicService.serviceType==='RESTAURANT'?'#a29100':touristicService.serviceType==='TRANSPORT'?'rgb(239 95 95)':touristicService.serviceType==='APART_HOTEL'?'rgb(72 124 140)':'#487c8c87')}"
                  color="primary" mat-raised-button>
            <span translate="entity.action.enter"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
