<form   name="deleteForm">
  <div class="modal-header">
    <div class="fl-popin__title">Change Tier status</div>
    <button (click)="clear()" aria-hidden="true" class="close" data-dismiss="modal" type="button">&times;
    </button>
  </div>
  <div class="modal-body">
    <deal-alert-error></deal-alert-error>
    <p>{{'global.tier.action' | translate}}</p>

    <div class="form-group col-md-12">
      <label class="form-control-label" for="commentaire">Comment</label>
      <textarea [(ngModel)]="comment" class="form-control" id="commentaire" name="commentaire"
                placeholder="your comment" type="text"></textarea>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="clear()" mat-raised-button type="reset" style=";margin-right: 10px">
      <i class="mdi mdi-18px mdi-cancel"></i>&nbsp;
      <span translate="entity.action.cancel"></span>
    </button>
    <button [disabled]="status==='ACTIVATE' || isSave" (click)="confirmChange('ACTIVATE')" mat-raised-button type="submit" style="margin-right: 10px">
      <i class="mdi mdi-18px " [class]="isSave?'mdi-reload mdi-spin':'mdi-lock-open md-green'"></i>&nbsp;
      <span translate="entity.action.activate" style="color: green"></span>
    </button>
    <button [disabled]="status==='DEACTIVATE' || isSave" (click)="confirmChange('DEACTIVATE')" mat-raised-button type="submit" style="margin-right: 10px">
      <i class="mdi  mdi-18px " [class]="isSave?'mdi-reload mdi-spin':'mdi-lock md-red'"></i>&nbsp;
      <span translate="entity.action.deactivate" style="color: red"></span>
    </button>
    <button *ngIf="tierCategory!=='CLIENT'" [disabled]="status==='PENDING' || isSave" (click)="confirmChange('PENDING')"   mat-raised-button type="submit" style="margin-right: 10px">
      <i class="mdi  mdi-18px " [class]="isSave?'mdi-reload mdi-spin':'mdi-timer-sand-complete md-orange'"></i>&nbsp;
      <span translate="entity.action.PENDING" style="color: orange"></span>
    </button>
    <button *ngIf="tierCategory!=='CLIENT'" [disabled]="status==='DENIED' || isSave" (click)="confirmChange('DENIED')" mat-raised-button type="submit" style="margin-right: 10px">
      <i class="mdi mdi-18px " [class]="isSave?'mdi-reload mdi-spin':'mdi-close-circle md-red '"></i>&nbsp;
      <span translate="entity.action.DENIED" style="color: red"></span>
    </button>
    <button [disabled]="status==='BLACK_LIST' || isSave" (click)="confirmChange('BLACK_LIST')" mat-raised-button type="submit">
      <i class="mdi  mdi-18px " [class]="isSave?'mdi-reload mdi-spin':'mdi-minus-circle md-black'"></i>&nbsp;
      <span translate="entity.action.BLACK_LIST" style="color: black"></span>
    </button>

  </div>
</form>
