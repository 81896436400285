import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {LoginService} from "../../../login/login.service";
import {KeycloakService} from "keycloak-angular";
import {AuthServerProvider} from "../../../core/auth/auth-jwt.service";

@Component({
  templateUrl: './expire-session-dialog.component.html',
})
export class ExpireSessionDialogComponent {

  constructor(private loginService: LoginService,
              protected readonly keycloak: KeycloakService,
              private authServerProvider: AuthServerProvider,
              protected activeModal: NgbActiveModal) {
  }

  cancel(): void {
    this.activeModal.dismiss();
    this.keycloak.logout().then();
    this.authServerProvider.logout().subscribe(() => {
    });
    this.activeModal.close('login');
  }

  confirmLogin(): void {
    this.keycloak.logout().then();
    this.authServerProvider.logout().subscribe(() => {
    });
    this.activeModal.close('login');
  }
}
