<form (ngSubmit)="confirmDelete()" name="deleteForm">
  <div class="modal-header">
    <div class="fl-popin__title" translate="entity.action.delete.title"></div>
    <button (click)="clear()" aria-hidden="true" class="close" data-dismiss="modal" type="button">&times;
    </button>
  </div>
  <div class="modal-body">
    <deal-alert-error></deal-alert-error>
    <p>{{'global.question' | translate}}</p>
  </div>
  <div class="modal-footer">
    <button (click)="clear()" class="btn btn-secondary" data-dismiss="modal" type="button">
      <span class="fa fa-ban"></span>&nbsp;<span translate="entity.action.cancel"></span>
    </button>
    <button class="btn moor-button-delete-red" type="submit">
      <span class="fa fa-remove"></span>&nbsp;<span translate="entity.action.delete"></span>
    </button>
  </div>
</form>
