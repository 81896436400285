import {TierCategoryEnum} from 'app/core/user/account.model';
import { GroupTier } from 'app/entities/group-tier/group-tier.model';
import {CityDto} from "./city-dto.model";

export const enum TitleEnum {
  'MR',
  'MRS',
  'MS',
  'MISS',
  'OTHER',
}

export const enum RoleInCompanyEnum {
  'CEO_MD_OWNER',
  'DIRECTOR',
  'SALES_MARKETING',
  'BDM',
  'RESERVATIONS',
  'OPERATIONS',
  'OTHER',
}

export class TierActivate {
  constructor(public tierPublicId?: string, public tierActif?: boolean) {
  }
}
export enum TierEnum {
  'groupTier',
  'street',
  'postCode',
  'phone',
  'email',
  'webSite',
  'genrlMangFirstName',
  'genrlMangSecondName',
  'genrlMangTel',
  'genrlMangEmail',
  'genrlMangActv',
  'oprMangFirstName',
  'oprMangSecondName',
  'oprMangTel',
  'oprMangEmail',
  'oprMangActv',
  'saleMangFirstName',
  'saleMangSecondName',
  'saleMangTel',
  'saleMangEmail',
  'saleMangActv',
  'resDepFirstName',
  'resDepSecondName',
  'resDepTel',
  'resDepEmail',
  'resDepActv',
  'accDepFirstName',
  'accDepSecondName',
  'accDepEmail',
  'accDepTel',
  'accDepActv',
}

export class Tier {
  constructor(
    public publicId?: string,
    public code?: string,
    public groupTier?: GroupTier,
    public groupPublicId?: string,
    public tierCategory?: TierCategoryEnum,
    public tierCategoryFind?: string,
    public companyName?: string,
    public country?: any,
    public countryId?: string,
    public respFirstName?: string,
    public respLastName?: string,
    public tierMail?: string,
    public tierTel?: string,
    public countryName?: string,
    public cityPublicId?: string,
    public cityName?: string,
    public city?: any,
    public street?: string,
    public postCode?: string,
    public phone?: string,
    public email?: string,
    public website?: string,
    public actvRss?: boolean,
    // General Manager
    public genrlMangFirstName?: string,
    public genrlMangSecondName?: string,
    public genrlMangTel?: string,
    public genrlMangEmail?: string,
    public genrlMangActv?: boolean,
    // operations Manager
    public oprMangFirstName?: string,
    public oprMangSecondName?: string,
    public oprMangTel?: string,
    public oprMangEmail?: string,
    public oprMangActv?: boolean,
    // Sales Manager
    public saleMangFirstName?: string,
    public saleMangSecondName?: string,
    public saleMangTel?: string,
    public saleMangEmail?: string,
    public saleMangActv?: boolean,
    // Reservation Departement
    public resDepFirstName?: string,
    public resDepSecondName?: string,
    public resDepEmail?: string,
    public resDepTel?: string,
    public resDepActv?: boolean,
    // Account Departement
    public accDepFirstName?: string,
    public accDepSecondName?: string,
    public accDepEmail?: string,
    public accDepTel?: string,
    public accDepActv?: boolean,
    public iataNumber?: string,
    public tierActif?: boolean,
    public tierComplete?: boolean,
    public tierSince?: any,
    public dtExpire?: any,
    public receivePromotion?: boolean,
    public deleted?: boolean,
    public tierStatus?: string
  ) {
  }
}
export class TierRequest1 {
  constructor(
    public groupPublicId?: string,
    public companyName?: string,
    public countryPublicId?: string,
    public city?: CityDto,
    public street?: string,
    public postCode?: string,
    public phone?: string,
    public email?: string,
    public website?: string,
  ) {
  }
}
export class TierRequest2 {
  constructor(
    public genrlMangFirstName?: string,
    public genrlMangSecondName?: string,
    public genrlMangTel?: string,
    public genrlMangEmail?: string,
    // operations Manager
    public oprMangFirstName?: string,
    public oprMangSecondName?: string,
    public oprMangTel?: string,
    public oprMangEmail?: string,
    // Sales Manager
    public saleMangFirstName?: string,
    public saleMangSecondName?: string,
    public saleMangTel?: string,
    public saleMangEmail?: string,
    // Reservation Departement
    public resDepFirstName?: string,
    public resDepSecondName?: string,
    public resDepTel?: string,
    public resDepEmail?: string,
    // Account Departement
    public accDepFirstName?: string,
    public accDepSecondName?: string,
    public accDepTel?: string,
    public accDepEmail?: string,
  ) {
  }
}
export class TierName{
  constructor(public firstName? :string,
              public lastName? :string,
              public phone? :string,
              public email? :string) {
  }
}
