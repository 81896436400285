import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'findCurrencyFromCodePipe'})
export class FindCurrencyFromCodePipe implements PipeTransform {
  private currencys: any = {
    AED: {name: 'United Arab Emirates Dirham'},
    AFN: {name: 'Afghanistan Afghani'},
    ALL: {name: 'Albania Lek'},
    AMD: {name: 'Armenia Dram'},
    ANG: {name: 'Netherlands Antilles Guilder'},
    AOA: {name: 'Angola Kwanza'},
    ARS: {name: 'Argentina Peso'},
    AUD: {name: 'Australia Dollar'},
    AWG: {name: 'Aruba Guilder'},
    AZN: {name: 'Azerbaijan New Manat'},
    BAM: {name: 'Bosnia and Herzegovina Convertible Marka'},
    BBD: {name: 'Barbados Dollar'},
    BDT: {name: 'Bangladesh Taka'},
    BGN: {name: 'Bulgaria Lev'},
    BHD: {name: 'Bahrain Dinar'},
    BIF: {name: 'Burundi Franc'},
    BMD: {name: 'Bermuda Dollar'},
    BND: {name: 'Brunei Darussalam Dollar'},
    BOB: {name: 'Bolivia Bolíviano'},
    BRL: {name: 'Brazil Real'},
    BSD: {name: 'Bahamas Dollar'},
    BTN: {name: 'Bhutan Ngultrum'},
    BWP: {name: 'Botswana Pula'},
    BYR: {name: 'Belarus Ruble'},
    BZD: {name: 'Belize Dollar'},
    CAD: {name: 'Canada Dollar'},
    CDF: {name: 'Congo/Kinshasa Franc'},
    CHF: {name: 'Switzerland Franc'},
    CLP: {name: 'Chile Peso'},
    CNY: {name: 'China Yuan Renminbi'},
    COP: {name: 'Colombia Peso'},
    CRC: {name: 'Costa Rica Colon'},
    CUC: {name: 'Cuba Convertible Peso'},
    CUP: {name: 'Cuba Peso'},
    CVE: {name: 'Cape Verde Escudo'},
    CZK: {name: 'Czech Republic Koruna'},
    DJF: {name: 'Djibouti Franc'},
    DKK: {name: 'Denmark Krone'},
    DOP: {name: 'Dominican Republic Peso'},
    DZD: {name: 'Algeria Dinar'},
    EGP: {name: 'Egypt Pound'},
    ERN: {name: 'Eritrea Nakfa'},
    ETB: {name: 'Ethiopia Birr'},
    EUR: {name: 'Euro Member Countries'},
    FJD: {name: 'Fiji Dollar'},
    FKP: {name: 'Falkland Islands (Malvinas) Pound'},
    GBP: {name: 'United Kingdom Pound'},
    GEL: {name: 'Georgia Lari'},
    GGP: {name: 'Guernsey Pound'},
    GHS: {name: 'Ghana Cedi'},
    GIP: {name: 'Gibraltar Pound'},
    GMD: {name: 'Gambia Dalasi'},
    GNF: {name: 'Guinea Franc'},
    GTQ: {name: 'Guatemala Quetzal'},
    GYD: {name: 'Guyana Dollar'},
    HKD: {name: 'Hong Kong Dollar'},
    HNL: {name: 'Honduras Lempira'},
    HRK: {name: 'Croatia Kuna'},
    HTG: {name: 'Haiti Gourde'},
    HUF: {name: 'Hungary Forint'},
    IDR: {name: 'Indonesia Rupiah'},
    ILS: {name: 'Israel Shekel'},
    IMP: {name: 'Isle of Man Pound'},
    INR: {name: 'India Rupee'},
    IQD: {name: 'Iraq Dinar'},
    IRR: {name: 'Iran Rial'},
    ISK: {name: 'Iceland Krona'},
    JEP: {name: 'Jersey Pound'},
    JMD: {name: 'Jamaica Dollar'},
    JOD: {name: 'Jordan Dinar'},
    JPY: {name: 'Japan Yen'},
    KES: {name: 'Kenya Shilling'},
    KGS: {name: 'Kyrgyzstan Som'},
    KHR: {name: 'Cambodia Riel'},
    KMF: {name: 'Comoros Franc'},
    KPW: {name: 'Korea (North) Won'},
    KRW: {name: 'Korea (South) Won'},
    KWD: {name: 'Kuwait Dinar'},
    KYD: {name: 'Cayman Islands Dollar'},
    KZT: {name: 'Kazakhstan Tenge'},
    LAK: {name: 'Laos Kip'},
    LBP: {name: 'Lebanon Pound'},
    LKR: {name: 'Sri Lanka Rupee'},
    LRD: {name: 'Liberia Dollar'},
    LSL: {name: 'Lesotho Loti'},
    LYD: {name: 'Libya Dinar'},
    MAD: {name: 'Morocco Dirham'},
    MDL: {name: 'Moldova Leu'},
    MGA: {name: 'Madagascar Ariary'},
    MKD: {name: 'Macedonia Denar'},
    MMK: {name: 'Myanmar (Burma) Kyat'},
    MNT: {name: 'Mongolia Tughrik'},
    MOP: {name: 'Macau Pataca'},
    MRO: {name: 'Mauritania Ouguiya'},
    MUR: {name: 'Mauritius Rupee'},
    MVR: {name: 'Maldives (Maldive Islands) Rufiyaa'},
    MWK: {name: 'Malawi Kwacha'},
    MXN: {name: 'Mexico Peso'},
    MYR: {name: 'Malaysia Ringgit'},
    MZN: {name: 'Mozambique Metical'},
    NAD: {name: 'Namibia Dollar'},
    NGN: {name: 'Nigeria Naira'},
    NIO: {name: 'Nicaragua Cordoba'},
    NOK: {name: 'Norway Krone'},
    NPR: {name: 'Nepal Rupee'},
    NZD: {name: 'New Zealand Dollar'},
    OMR: {name: 'Oman Rial'},
    PAB: {name: 'Panama Balboa'},
    PEN: {name: 'Peru Sol'},
    PGK: {name: 'Papua New Guinea Kina'},
    PHP: {name: 'Philippines Peso'},
    PKR: {name: 'Pakistan Rupee'},
    PLN: {name: 'Poland Zloty'},
    PYG: {name: 'Paraguay Guarani'},
    QAR: {name: 'Qatar Riyal'},
    RON: {name: 'Romania New Leu'},
    RSD: {name: 'Serbia Dinar'},
    RUB: {name: 'Russia Ruble'},
    RWF: {name: 'Rwanda Franc'},
    SAR: {name: 'Saudi Arabia Riyal'},
    SBD: {name: 'Solomon Islands Dollar'},
    SCR: {name: 'Seychelles Rupee'},
    SDG: {name: 'Sudan Pound'},
    SEK: {name: 'Sweden Krona'},
    SGD: {name: 'Singapore Dollar'},
    SHP: {name: 'Saint Helena Pound'},
    SLL: {name: 'Sierra Leone Leone'},
    SOS: {name: 'Somalia Shilling'},
    SPL: {name: 'Seborga Luigino'},
    SRD: {name: 'Suriname Dollar'},
    STD: {name: 'São Tomé and Príncipe Dobra'},
    SVC: {name: 'El Salvador Colon'},
    SYP: {name: 'Syria Pound'},
    SZL: {name: 'Swaziland Lilangeni'},
    THB: {name: 'Thailand Baht'},
    TJS: {name: 'Tajikistan Somoni'},
    TMT: {name: 'Turkmenistan Manat'},
    TND: {name: 'Tunisia Dinar'},
    TOP: {name: "Tonga Pa'anga"},
    TRY: {name: 'Turkey Lira'},
    TTD: {name: 'Trinidad and Tobago Dollar'},
    TVD: {name: 'Tuvalu Dollar'},
    TWD: {name: 'Taiwan New Dollar'},
    TZS: {name: 'Tanzania Shilling'},
    UAH: {name: 'Ukraine Hryvnia'},
    UGX: {name: 'Uganda Shilling'},
    USD: {name: 'United States Dollar'},
    UYU: {name: 'Uruguay Peso'},
    UZS: {name: 'Uzbekistan Som'},
    VEF: {name: 'Venezuela Bolivar'},
    VND: {name: 'Viet Nam Dong'},
    VUV: {name: 'Vanuatu Vatu'},
    WST: {name: 'Samoa Tala'},
    XAF: {name: 'Communauté Financière Africaine (BEAC) CFA Franc BEAC'},
    XCD: {name: 'East Caribbean Dollar'},
    XDR: {name: 'International Monetary Fund (IMF) Special Drawing Rights'},
    XOF: {name: 'Communauté Financière Africaine (BCEAO) Franc'},
    XPF: {name: 'Comptoirs Français du Pacifique (CFP) Franc'},
    YER: {name: 'Yemen Rial'},
    ZAR: {name: 'South Africa Rand'},
    ZMW: {name: 'Zambia Kwacha'},
    ZWD: {name: 'Zimbabwe Dollar'},
    // jhipster-needle-i18n-language-key-pipe - JHipster will add/remove currencys in this object
  };

  transform(code: string): string {
    return this.currencys[code].name;
  }
}
@Pipe({name: 'findCurrencyNewFromCodePipe'})
export class FindCurrencyNewFromCodePipe implements PipeTransform {
  private currencys: any = {
    // CAD: {name: 'Canada Dollar'},
    // DKK: {name: 'Denmark Krone'},
    EUR: {name: 'Euro Member Countries'},
    // SAR: {name: 'Saudi Arabia Riyal'},
    CHF: {name: 'Switzerland Franc'},
    TND: {name: 'Tunisia Dinar'},
    // TRY: {name: 'Turkey Lira'},
    GBP: {name: 'United Kingdom Pound'},
    USD: {name: 'United States Dollar'},
    // jhipster-needle-i18n-language-key-pipe - JHipster will add/remove currencys in this object
  };

  transform(code: string): string {
    return this.currencys[code].name;
  }
}
