import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {TouristicService} from './touristic-service.model';
import {TouristicServiceCreate} from './touristic-service-create.model';
import {TouristicServiceComplete} from './touristic-service-complete.model';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {filter, map} from 'rxjs/operators';
import * as moment from 'moment';
import {createRequestOption} from 'app/core/request/request-util';
import {StatResponse, TouristicServiceSimpleData} from 'app/entities/touristic-service/touristic-service.model';
import {TouristicServiceBriefDesc} from "./touristic-service-briefDesc.model";
import {TransportDescDto} from "../response-tier";
import {TransportResponseFilter} from "../response-tier/sub-request/sub-response-tier-filter.model";

@Injectable({
  providedIn: 'root',
})
export class TouristicServiceService {
  private resourceUrl = '/dealsetting/api/touristic-services';
  private resourceUrl2 = '/dealsetting/api';
  private urlUpdate: any;

  constructor(private http: HttpClient) {
  }

  create(tierPublicId: string, touristicService: TouristicServiceCreate): Observable<HttpResponse<TouristicService>> {
    const copy: TouristicServiceCreate = Object.assign({}, touristicService);
    return this.http.post<any>(this.resourceUrl2 + '/tier/' + tierPublicId + '/touristic-service/', copy, {observe: 'response'});
  }

  createDraft(tierPublicId: string, servicePublicId: string): Observable<HttpResponse<TouristicServiceComplete>> {
    const copy: TouristicServiceCreate = Object.assign({}, {});
    return this.http.post(this.resourceUrl2 + '/tier/' + tierPublicId + '/touristic-service/' + servicePublicId, copy, {
      observe: 'response',
    });
  }

  update(touristicService: TouristicService): Observable<HttpResponse<TouristicService>> {
    const copy = this.convert(touristicService);
    return this.http.put(this.resourceUrl, copy, {observe: 'response'});
  }

  find(tierPublicId: string, id: string, status: string): Observable<HttpResponse<TouristicServiceComplete>> {
    return this.http
      .get(this.resourceUrl2 + '/tier/' + tierPublicId + '/touristic-service/' + status + '/' + id + '/join', {observe: 'response'})
      .pipe(
        filter((mayBeOk: HttpResponse<TouristicServiceComplete>) => mayBeOk.ok),
        map((res: any) => {
          return res;
        })
      );
  }

  findTouristicServiceDescription(tierPublicId: string, id: string): Observable<HttpResponse<TouristicServiceBriefDesc>> {
    return this.http.get(this.resourceUrl2 + '/tier/' + tierPublicId + '/touristic-service-brief-desc/' + id, {observe: 'response'})
      .pipe(
        filter((mayBeOk: HttpResponse<TouristicServiceBriefDesc>) => mayBeOk.ok),
        map((res: any) => {
          res.body.lastYearInspc = res.body.lastYearInspc != null ? moment(res.body.touristicService.lastYearInspc) : null;
          res.body.lastYearRefbsh = res.body.lastYearRefbsh != null ? moment(res.body.touristicService.lastYearRefbsh) : null;
          res.body.yearBuilt = res.body.yearBuilt != null ? moment(res.body.touristicService.yearBuilt) : null;
          return res;
        })
      );
  }

  findTransportResult(transportResponseFilter: TransportResponseFilter, airport: string, destination: string, arriveDate: any, arriveTime: any,
                      returnDate: any, returnTime: any, flightNumber: string, distance: number,
                      passengerNbr: number, mediumLuggage: number, smallHandLuggage: number, cityPublicId: string, roundTrip?: boolean, req?: any): Observable<HttpResponse<TransportDescDto[]>> {
    let options = createRequestOption(req);
    if (airport != null) {
      options = options.set('airport.equals', airport);
    }
    if (destination != null) {
      options = options.set('destination.equals', destination);
    }
    if (arriveDate != null) {
      options = options.set('arriveDate.equals', new Date(Date.parse(arriveDate)).toLocaleString());
    }
    if (arriveTime != null) {
      options = options.set('arriveTime.equals', arriveTime);
    }
    if (roundTrip) {
      options = options.set('roundTrip.equals', roundTrip);
      options = options.set('returnDate.equals', new Date(Date.parse(returnDate)).toLocaleString());
      options = options.set('returnTime.equals', returnTime);
    }
    if (flightNumber != null) {
      options = options.set('flightNumber.equals', flightNumber);
    }
    if (distance != null) {
      options = options.set('distance.equals', distance);
    }
    if (passengerNbr != null) {
      options = options.set('passengerNbr.equals', passengerNbr);
    }
    if (mediumLuggage != null) {
      options = options.set('mediumLuggage.equals', mediumLuggage);
    }
    if (smallHandLuggage != null) {
      options = options.set('smallHandLuggage.equals', smallHandLuggage);
    }
    if (cityPublicId != null) {
      options = options.set('city.equals', cityPublicId);
    }
    if (transportResponseFilter != null) {
      if (transportResponseFilter.facilities != null && transportResponseFilter.facilities.length > 0) {
        options = options.set('facilities.in', transportResponseFilter.facilities.join(','));
      }
      if (transportResponseFilter.accessibilities != null && transportResponseFilter.accessibilities.length > 0) {
        options = options.set('accessibilities.in', transportResponseFilter.accessibilities.join(','));
      }
      if (transportResponseFilter.vehicleClasses != null && transportResponseFilter.vehicleClasses.length > 0) {
        options = options.set('vehicleClasses.in', transportResponseFilter.vehicleClasses.join(','));
      }
      if (transportResponseFilter.driverLanguages != null && transportResponseFilter.driverLanguages.length > 0) {
        options = options.set('driverLanguages.in', transportResponseFilter.driverLanguages.join(','));
      }
      if (transportResponseFilter.minPrice != null) {
        options = options.set('minPrice.equals', transportResponseFilter.minPrice);
      }
      if (transportResponseFilter.maxPrice != null) {
        options = options.set('maxPrice.equals', transportResponseFilter.maxPrice);
      }
      if (transportResponseFilter.driverMale) {
        options = options.set('driverMale.equals', true);
      }
      if (transportResponseFilter.driverFemale) {
        options = options.set('driverFemale.equals', true);
      }
    }
    return this.http.get(this.resourceUrl2 + '/service-transfer', {params: options, observe: 'response'})
      .pipe(
        filter((mayBeOk: HttpResponse<any>) => mayBeOk.ok),
        map((res: any) => {
          return res;
        })
      );
  }

  findTransportCityTourResult(transportResponseFilter: TransportResponseFilter, cityTourDate: any, cityTourTime: any,
                              duration: any, passengerNbr: number, cityPublicId: string, req?: any): Observable<HttpResponse<TransportDescDto[]>> {
    let options = createRequestOption(req);

    if (cityTourDate != null) {
      options = options.set('cityTourDate.equals', cityTourDate);
    }
    if (cityTourTime != null) {
      options = options.set('cityTourTime.equals', cityTourTime);
    }
    if (passengerNbr != null) {
      options = options.set('passengerNbr.equals', passengerNbr);
    }
    if (duration != null) {
      options = options.set('duration.equals', duration);
    }
    if (cityPublicId != null) {
      options = options.set('city.equals', cityPublicId);
    }
    if (transportResponseFilter != null) {
      if (transportResponseFilter.facilities != null && transportResponseFilter.facilities.length > 0) {
        options = options.set('facilities.in', transportResponseFilter.facilities.join(','));
      }
      if (transportResponseFilter.accessibilities != null && transportResponseFilter.accessibilities.length > 0) {
        options = options.set('accessibilities.in', transportResponseFilter.accessibilities.join(','));
      }
      if (transportResponseFilter.vehicleClasses != null && transportResponseFilter.vehicleClasses.length > 0) {
        options = options.set('vehicleClasses.in', transportResponseFilter.vehicleClasses.join(','));
      }
      if (transportResponseFilter.driverLanguages != null && transportResponseFilter.driverLanguages.length > 0) {
        options = options.set('driverLanguages.in', transportResponseFilter.driverLanguages.join(','));
      }
      if (transportResponseFilter.minPrice != null) {
        options = options.set('minPrice.equals', transportResponseFilter.minPrice);
      }
      if (transportResponseFilter.maxPrice != null) {
        options = options.set('maxPrice.equals', transportResponseFilter.maxPrice);
      }
      if (transportResponseFilter.driverMale) {
        options = options.set('driverMale.equals', true);
      }
      if (transportResponseFilter.driverFemale) {
        options = options.set('driverFemale.equals', true);
      }
    }
    return this.http.get(this.resourceUrl2 + '/service-citytour', {params: options, observe: 'response'})
      .pipe(
        filter((mayBeOk: HttpResponse<any>) => mayBeOk.ok),
        map((res: any) => {
          return res;
        })
      );
  }

  findTransportTour4CitiesResult(transportResponseFilter: TransportResponseFilter, cityTourDate: any, cityTourTime: any,
                                 waitingTime: any, passengerNbr: number, cityFromPublicId: string, cityToPublicId: string, req?: any): Observable<HttpResponse<TransportDescDto[]>> {
    let options = createRequestOption(req);

    if (cityTourDate != null) {
      options = options.set('tour4CitiesDate.equals', cityTourDate);
    }
    if (cityTourTime != null) {
      options = options.set('tour4CitiesTime.equals', cityTourTime);
    }
    if (passengerNbr != null) {
      options = options.set('passengerNbr.equals', passengerNbr);
    }
    if (waitingTime != null) {
      options = options.set('waitingTime.equals', waitingTime);
    }
    if (cityFromPublicId != null) {
      options = options.set('cityFrom.equals', cityFromPublicId);
    }
    if (cityToPublicId != null) {
      options = options.set('cityTo.equals', cityToPublicId);
    }
    if (transportResponseFilter != null) {
      if (transportResponseFilter.facilities != null && transportResponseFilter.facilities.length > 0) {
        options = options.set('facilities.in', transportResponseFilter.facilities.join(','));
      }
      if (transportResponseFilter.accessibilities != null && transportResponseFilter.accessibilities.length > 0) {
        options = options.set('accessibilities.in', transportResponseFilter.accessibilities.join(','));
      }
      if (transportResponseFilter.vehicleClasses != null && transportResponseFilter.vehicleClasses.length > 0) {
        options = options.set('vehicleClasses.in', transportResponseFilter.vehicleClasses.join(','));
      }
      if (transportResponseFilter.driverLanguages != null && transportResponseFilter.driverLanguages.length > 0) {
        options = options.set('driverLanguages.in', transportResponseFilter.driverLanguages.join(','));
      }
      if (transportResponseFilter.minPrice != null) {
        options = options.set('minPrice.equals', transportResponseFilter.minPrice);
      }
      if (transportResponseFilter.maxPrice != null) {
        options = options.set('maxPrice.equals', transportResponseFilter.maxPrice);
      }
      if (transportResponseFilter.driverMale) {
        options = options.set('driverMale.equals', true);
      }
      if (transportResponseFilter.driverFemale) {
        options = options.set('driverFemale.equals', true);
      }
    }
    return this.http.get(this.resourceUrl2 + '/service-tour4Cities', {params: options, observe: 'response'})
      .pipe(
        filter((mayBeOk: HttpResponse<any>) => mayBeOk.ok),
        map((res: any) => {
          return res;
        })
      );
  }

  // TODO change to sessionStorige
  getOnServiceTypeCountry(tierPublicId: string): Observable<HttpResponse<any>> {
    return this.http.get(this.resourceUrl2 + '/tier/' + tierPublicId + '/touristic-service/' + 'country/serviceType', {
      observe: 'response',
    });
  }

  query(tierPublicId: string, req?: any): Observable<HttpResponse<TouristicService[]>> {
    const options = createRequestOption(req);
    return this.http.get<TouristicService[]>(this.resourceUrl2 + '/tier/' + tierPublicId + '/touristic-service', {
      params: options,
      observe: 'response',
    });
  }

  getAllSimpleData(tierPublicId?: string, req?: any): Observable<HttpResponse<TouristicServiceSimpleData[]>> {
    const options = createRequestOption(req);
    return this.http.get<TouristicService[]>(this.resourceUrl2 + '/tier/' + tierPublicId + '/touristic-service/simple-data', {
      params: options,
      observe: 'response',
    });
  }

  completude(tierPublicId: string, servicePublicId: string): Observable<HttpResponse<TouristicServiceComplete>> {
    return this.http.get<TouristicServiceComplete>(
      this.resourceUrl2 + '/tier/' + tierPublicId + '/touristic-service/' + servicePublicId + '/completude',
      {
        observe: 'response',
      }
    );
  }

  findAll(tierPublicId?: string, serviceFilter?: any, req?: any): Observable<HttpResponse<TouristicService[]>> {
    let options = createRequestOption(req);
    if (serviceFilter) {
      if (serviceFilter.status) {
        options = options.set('status.equals', serviceFilter.status);
      }

      if (serviceFilter.serviceType) {
        options = options.set('serviceType.equals', serviceFilter.serviceType);
      }
      if (serviceFilter.country) {
        options = options.set('country.equals', serviceFilter.country);
      }
      if (serviceFilter.complete != null) {
        options = options.set('complete.equals', serviceFilter.complete);
      }
    }
    return this.http.get<TouristicService[]>(this.resourceUrl2 + '/tier/' + tierPublicId + '/touristic-service/pagination', {
      params: options,
      observe: 'response',
    });
  }

  findAllAdmin(serviceFilter: any, req?: any): Observable<HttpResponse<TouristicService[]>> {
    let options = createRequestOption(req);
    if (serviceFilter) {
      if (serviceFilter.status) {
        options = options.set('status.equals', serviceFilter.status);
      }
      if (serviceFilter.tierPublicId) {
        options = options.set('tierPublicId.equals', serviceFilter.tierPublicId);
      }
      if (serviceFilter.serviceType) {
        options = options.set('serviceType.equals', serviceFilter.serviceType);
      }
      if (serviceFilter.country) {
        options = options.set('country.equals', serviceFilter.country);
      }
      if (serviceFilter.complete != null) {
        options = options.set('complete.equals', serviceFilter.complete);
      }
    }
    return this.http.get<TouristicService[]>(this.resourceUrl2 + '/touristic-service/pagination', {
      params: options,
      observe: 'response',
    });
  }

  getState(req?: any): Observable<HttpResponse<StatResponse>> {
    const options = createRequestOption(req);
    return this.http.get<StatResponse>(this.resourceUrl2 + '/tier/state', {
      params: options,
      observe: 'response',
    });
  }

  getStatusTouristicService(tierPublicId: string | undefined): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.resourceUrl2 + '/tier/' + tierPublicId + '/touristic-service/status', {
      observe: 'response',
    });
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, {observe: 'response'});
  }

  getUrlUpdate(tierPublicId: string, servicePublicId: string, urlSufix: string) {
    return (this.urlUpdate = this.resourceUrl2 + '/tier/' + tierPublicId + '/touristic-service/' + servicePublicId + '/' + urlSufix);
  }

  updateAttribute(
    tierPublicId: string,
    servicePublicId: string,
    value: any,
    attribut: string
  ): Observable<HttpResponse<TouristicServiceComplete>> {
    return this.http.put(this.getUrlUpdate(tierPublicId, servicePublicId, attribut), value, {observe: 'response'});
  }

  complete(tierPublicId: string, servicePublicId: string): Observable<HttpResponse<any>> {
    return this.http.put(
      '/dealsetting/api/tier/' + tierPublicId + '/touristic-service/' + servicePublicId + '/complete',
      {},
      {observe: 'response'}
    );
  }

  deleteDraft(tierPublicId: string, servicePublicId: string): Observable<HttpResponse<any>> {
    return this.http.delete('/dealsetting/api/tier/' + tierPublicId + '/touristic-service/' + servicePublicId + '/draft/delete', {
      observe: 'response',
    });
  }

  improve(tierPublicId: string, servicePublicId: string, comments: string): Observable<HttpResponse<any>> {
    return this.http.put(
      '/dealsetting/api/tier/' + tierPublicId + '/touristic-service/' + servicePublicId + '/improve',
      {comment: comments},
      {observe: 'response'}
    );
  }

  disimprove(tierPublicId: string, servicePublicId: string, comments: string): Observable<HttpResponse<any>> {
    return this.http.put(
      '/dealsetting/api/tier/' + tierPublicId + '/touristic-service/' + servicePublicId + '/disimprove',
      {comment: comments},
      {observe: 'response'}
    );
  }

  getEVoucherAmenities(tierPublicId: string, id: string): Observable<HttpResponse<string []>> {
    return this.http.get<string []>(this.resourceUrl2 + '/tier/' + tierPublicId + '/touristic-service/' + id + '/evoucher/amenities', {observe: 'response'})
  }

  getEVoucherLocation(tierPublicId: string, id: string): Observable<HttpResponse<string []>> {
    return this.http.get<string []>(this.resourceUrl2 + '/tier/' + tierPublicId + '/touristic-service/' + id + '/evoucher/location', {observe: 'response'})
  }

  getEVoucherImportantInformation(tierPublicId: string, id: string): Observable<HttpResponse<string []>> {
    return this.http.get<string []>(this.resourceUrl2 + '/tier/' + tierPublicId + '/touristic-service/' + id + '/evoucher/important-information', {observe: 'response'})
  }

  /**
   * Convert a TouristicService to a JSON which can be sent to the server.
   */
  private convert(touristicService: TouristicService): TouristicService {
    const copy: TouristicService = Object.assign({}, touristicService);
    return copy;
  }

  updateMyEvoucher(tierPublicId: string, servicePublicId: string, selectedAmenities: string[],selectedTopAmenities: string[],
                   selectedLocations: string[], selectedImportantInformations: string[]) {
    return this.http.put(
      '/dealsetting/api/tier/' + tierPublicId + '/touristic-service/' + servicePublicId + '/evoucher',
      {amenities: selectedAmenities,topAmenities: selectedTopAmenities, locations: selectedLocations, importantInformations: selectedImportantInformations},
      {observe: 'response'}
    );
  }
  getMyEvoucher(tierPublicId: string, servicePublicId: string) :Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(
      '/dealsetting/api/tier/' + tierPublicId + '/touristic-service/' + servicePublicId + '/evoucher');
  }
}
