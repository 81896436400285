import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';

import {CurrentTierService} from './current-tier.service';
import {Tier, TierRequest1, TierRequest2} from 'app/shared/model/tier.model';
import {map} from 'rxjs/operators';
import {SERVER_PRAM_API_URL} from 'app/app.constants';
import {createRequestOption} from 'app/core/request/request-util';

@Injectable({
  providedIn: 'root',
})
export class TierService {
  private resourceUrl = SERVER_PRAM_API_URL + 'api/tier';
  private resourceUrlAdmin = SERVER_PRAM_API_URL + 'api/admin/tier';
  private urlUpdate = '';

  constructor(private http: HttpClient, private currentBrandService: CurrentTierService) {
  }

  getTier() {
    return this.currentBrandService.getTier();
  }

  create(tier: Tier): Observable<HttpResponse<Tier>> {
    const copy = this.convert(tier);
    return this.http.post(this.resourceUrl, copy, {observe: 'response'}).pipe(
      map((res: HttpResponse<Tier>) => this.convertItemFromServer(res))
    )
  }

  update(tier: Tier): Observable<HttpResponse<Tier>> {
    const copy = this.convert(tier);
    return this.http.put(this.resourceUrl, copy, {observe: 'response'}).pipe(
      map((res: HttpResponse<Tier>) => this.convertItemFromServer(res))
    );
  }

  getUrlUpdate(tierPublicId: string, urlSufix: string) {
    return (this.urlUpdate = this.resourceUrl + '/' + tierPublicId + '/' + urlSufix);
  }

  updateAttribute(tierPublicId: string, value: any, attribut: string): Observable<HttpResponse<Tier>> {
    return this.http.put(this.getUrlUpdate(tierPublicId, attribut), value, {observe: 'response'}).pipe(
      map((res: HttpResponse<Tier>) => this.convertTierCompleteFromServer(res))
    );
  }

  updateTab1(tierPublicId: string, value: TierRequest1): Observable<HttpResponse<Tier>> {
    return this.http.put(this.getUrlUpdate(tierPublicId, 'tab1'), value, {observe: 'response'}).pipe(
      map((res: HttpResponse<Tier>) => this.convertTierCompleteFromServer(res))
    );
  }

  updateTab2(tierPublicId: string, value: TierRequest2): Observable<HttpResponse<Tier>> {
    return this.http.put(this.getUrlUpdate(tierPublicId, 'tab2'), value, {observe: 'response'}).pipe(
      map((res: HttpResponse<Tier>) => this.convertTierCompleteFromServer(res))
    );
  }

  changeStatus(tierPublicId: string, status: string, tierComment?: String): Observable<HttpResponse<any>> {
    if (status === 'ACTIVATE') {
      return this.http.put('/dealsetting/api/tier/' + tierPublicId + '/activate', {comment: tierComment}, {observe: 'response'});
    } else if (status === 'DEACTIVATE') {
      return this.http.put('/dealsetting/api/tier/' + tierPublicId + '/deactivate', {comment: tierComment}, {observe: 'response'});
    } else if (status === 'DENIED') {
      return this.http.put('/dealsetting/api/tier/' + tierPublicId + '/denied', {comment: tierComment}, {observe: 'response'});
    } else if (status === 'PENDING') {
      return this.http.put('/dealsetting/api/tier/' + tierPublicId + '/pending', {comment: tierComment}, {observe: 'response'});
    } else if (status === 'BLACK_LIST') {
      return this.http.put('/dealsetting/api/tier/' + tierPublicId + '/blacklist', {comment: tierComment}, {observe: 'response'});
    }
  }

  activate(tierPublicId: string, tierComment: String): Observable<HttpResponse<any>> {
    return this.http.put('/dealsetting/api/tier/' + tierPublicId + '/activate', {}, {observe: 'response'});
  }

  addComment(tierPublicId: string, tierComment: String): Observable<HttpResponse<any>> {
    return this.http.put('/dealsetting/api/tier/' + tierPublicId + '/comment', {comment: tierComment}, {observe: 'response'});
  }

  getComment(tierPublicId: string): Observable<HttpResponse<any>> {
    return this.http.get('/dealsetting/api/tier/' + tierPublicId + '/comment', {observe: 'response'});
  }

  deactivate(tierPublicId: string): Observable<HttpResponse<any>> {
    return this.http.put('/dealsetting/api/tier/' + tierPublicId + '/deactivate', {}, {observe: 'response'});
  }

  complete(tierPublicId: string): Observable<HttpResponse<any>> {
    return this.http.put('/dealsetting/api/tier/' + tierPublicId + '/complete', {}, {observe: 'response'});
  }

  improve(tierPublicId: string): Observable<HttpResponse<any>> {
    return this.http.put('/dealsetting/api/tier/' + tierPublicId + '/improve', {}, {observe: 'response'});
  }

  disimprove(tierPublicId: string): Observable<HttpResponse<any>> {
    return this.http.put('/dealsetting/api/tier/' + tierPublicId + '/disimprove', {}, {observe: 'response'});
  }

  find(tierPublicId: string): Observable<HttpResponse<Tier>> {
    return this.http.get(`${this.resourceUrl}/${tierPublicId}`, {observe: 'response'});
  }
  findForAdmin(tierPublicId: string): Observable<HttpResponse<Tier>> {
    return this.http.get(`${this.resourceUrlAdmin}/${tierPublicId}`, {observe: 'response'});
  }

  query(tierFind: Tier, req?: any): Observable<HttpResponse<Tier[]>> {
    let options = createRequestOption(req);
    if (tierFind) {
      if (tierFind.companyName) {
        options = options.set('companyName.contains', tierFind.companyName);
      }
      if (tierFind.groupPublicId) {
        options = options.set('groupPublicId.equals', tierFind.groupPublicId);
      }
      if (tierFind.tierActif != null) {
        options = options.set('tierActif.equals', tierFind.tierActif + '');
      }
      if (tierFind.countryName) {
        options = options.set('countryName.equals', tierFind.countryName);
      }
      if (tierFind.tierCategoryFind) {
        options = options.set('tierCategory.in', tierFind.tierCategoryFind);
      }
      if (tierFind.tierStatus) {
        options = options.set('tierStatus.in', tierFind.tierStatus);
      }
    }
    return this.http
      .get<Tier[]>(this.resourceUrl, {params: options, observe: 'response'})
      .pipe(map((res: HttpResponse<Tier[]>) => this.convertResponse(res)));
  }

  getAllCode(req?: any): Observable<string[]> {
    const options = createRequestOption(req);
    return this.http
      .get<string[]>(`${this.resourceUrl}/code/all`, {params: options, observe: 'response'})
      .pipe(map((res: HttpResponse<string[]>) => res.body || []));
  }

  delete(publicId: string): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.resourceUrl}/${publicId}`, {observe: 'response'});
  }

  private convertResponse(res: HttpResponse<Tier[]>): HttpResponse<Tier[]> {
    const jsonResponse = res.body || [];
    const result = [];
    for (let i = 0; i < jsonResponse.length; i++) {
      result.push(this.convertItemFromServer2(jsonResponse[i]));
    }

    return new HttpResponse({body: result, headers: res.headers, status: res.status});
  }

  /**
   * Convert a returned JSON object to Tier.
   */
  private convertItemFromServer(json: HttpResponse<Tier>): HttpResponse<Tier> {
    const entity: Tier = Object.assign(new Tier(), json.body);
    // entity.tierSince = this.dateUtils.convertDateTimeFromServer(json.body.tierSince);
    // entity.dtStart = this.dateUtils.convertDateTimeFromServer(json.body.dtStart);
    // entity.dtExpire = this.dateUtils.convertDateTimeFromServer(json.body.dtExpire);
    return new HttpResponse({body: entity, headers: json.headers, status: json.status});
  }

  private convertItemFromServer2(json: Tier): Tier {
    const entity: Tier = Object.assign(new Tier(), json);
    // entity.tierSince = this.dateUtils.convertDateTimeFromServer(json.tierSince);
    // entity.dtStart = this.dateUtils.convertDateTimeFromServer(json.dtStart);
    // entity.dtExpire = this.dateUtils.convertDateTimeFromServer(json.dtExpire);
    return entity;
  }

  private convertTierCompleteFromServer(json: HttpResponse<Tier>): HttpResponse<Tier> {
    const entity: Tier = Object.assign(new Tier(), json.body);
    // entity.tier.tierSince = this.dateUtils.convertDateTimeFromServer(json.body.tier.tierSince);
    // entity.tier.dtStart = this.dateUtils.convertDateTimeFromServer(json.body.tier.dtStart);
    // entity.tier.dtExpire = this.dateUtils.convertDateTimeFromServer(json.body.tier.dtExpire);
    return new HttpResponse({body: entity, headers: json.headers, status: json.status});
  }

  /**
   * Convert a Tier to a JSON which can be sent to the server.
   */
  private convert(tier: Tier): Tier {
    const copy: Tier = Object.assign({}, tier);

    // copy.tierSince = this.dateUtils.toDate(tier.tierSince);
    //
    // copy.dtStart = this.dateUtils.toDate(tier.dtStart);
    //
    // copy.dtExpire = this.dateUtils.toDate(tier.dtExpire);
    return copy;
  }
}
