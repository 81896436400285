import {Routes} from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('../../layouts/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'page',
    // loadChildren:  () => CardsModule
    loadChildren: () => import('../../entities/entity.module').then(m => m.EntitiesModule),
  },
];
