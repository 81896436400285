import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Subject} from "rxjs";

// TODO : pbo : Rename this service : AlertService, or something like that
@Injectable({providedIn: 'root'})
export class UtilsService {
  scrolling$ = new Subject<boolean>();

  constructor(private translateService: TranslateService) {
  }

  setScroll(scroll: boolean | null) {
    this.scrolling$.next(scroll);
  }

  getScrollObservable(): Observable<boolean | null> {
    return this.scrolling$.asObservable();
  }

  getAllTitle(): any[] {
    return [
      {value: null, name: this.translateService.instant('title.selectTitle')},
      {value: 'MR', name: this.translateService.instant('title.MR')},
      {value: 'MRS', name: this.translateService.instant('title.MRS')},
      {value: 'MS', name: this.translateService.instant('title.MS')},
      {value: 'MISS', name: this.translateService.instant('title.MISS')},
      {value: 'SIR', name: this.translateService.instant('title.SIR')},
      {value: 'LADY', name: this.translateService.instant('title.LADY')},
      {value: 'KING', name: this.translateService.instant('title.KING')},
      {value: 'QUEEN', name: this.translateService.instant('title.QUEEN')},
      {value: 'MINISTER', name: this.translateService.instant('title.MINISTER')},
      {value: 'HRH', name: this.translateService.instant('title.HRH')},
      {value: 'SHEIKH', name: this.translateService.instant('title.SHEIKH')},
      {value: 'SHIKHA', name: this.translateService.instant('title.SHIKHA')},
      {value: 'LORD', name: this.translateService.instant('title.LORD')},
      {value: 'DR', name: this.translateService.instant('title.DR')},
      {value: 'PROF', name: this.translateService.instant('title.PROF')},
      {value: 'OTHER', name: this.translateService.instant('title.OTHER')},
    ];
  }

  getAllTitleProvider(): any[] {
    return [
      {value: null, name: this.translateService.instant('title.selectTitle')},
      {value: 'MR', name: this.translateService.instant('title.MR')},
      {value: 'MRS', name: this.translateService.instant('title.MRS')},
      {value: 'MS', name: this.translateService.instant('title.MS')},
      {value: 'MISS', name: this.translateService.instant('title.MISS')},
      {value: 'OTHER', name: this.translateService.instant('title.OTHER')},
    ];
  }

  getAllRoleInCompanys(): any[] {
    return [
      {value: 'CEO_MD_OWNER', name: 'roleInCompany.CEO-MD-OWNER'},
      {value: 'DIRECTOR', name: 'roleInCompany.DIRECTOR'},
      {value: 'SALES_MARKETING', name: 'roleInCompany.SALES-MARKETING'},
      {value: 'BDM', name: 'roleInCompany.BDM'},
      {value: 'RESERVATIONS', name: 'roleInCompany.RESERVATIONS'},
      {value: 'OPERATIONS', name: 'roleInCompany.OPERATIONS'},
      {value: 'OTHER', name: 'roleInCompany.OTHER'},
    ];
  }
}
