import {Pipe, PipeTransform} from '@angular/core';
import {ARRANGEMENT} from 'app/shared/constants/arrangement.constants';

@Pipe({name: 'fromArrangement'})
export class ArrangementPipe implements PipeTransform {
  private stars: any[] = ARRANGEMENT;

  transform(star: string | undefined): string {
    const filter = this.stars.filter(s => s.id === star);
    return filter && filter.length > 0 ? filter[0].itemName : '';
  }
}

@Pipe({name: 'fromArrangementIcon'})
export class ArrangementIconPipe implements PipeTransform {
  private stars: any[] = ARRANGEMENT;

  transform(star: string | undefined): string {
    const filter = this.stars.filter(s => s.id === star);
    return filter && filter.length > 0 ? filter[0].icon : '';
  }
}
