<nav class=" header-style navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}"
     [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center" data-toggle="collapse" (click)="toggleSidebar()">
        <i class="ft-menu font-medium-3"></i>
      </div>
      <ul class="navbar-nav" (clickOutside)="toggleSearchOpenClass(false)">
        <li class="nav-item mr-2 d-none d-lg-block">
          <a class="nav-link apptogglefullscreen" id="navbar-fullscreen" href="javascript:;" appToggleFullscreen
             (click)="ToggleClass()"><i class=" {{toggleClass}} font-medium-3"></i></a>
        </li>
<!--        <li class="nav-item nav-search">-->
<!--          <a (click)="toggleSearchOpenClass(true)" class="nav-link nav-link-search" href="javascript:" id="navbar-search">-->
<!--            <i class="ft-search font-medium-3"></i></a>-->
<!--          <div class="search-input {{searchOpenClass}}">-->
<!--            <div class="search-input-icon">-->
<!--              <i class="ft-search font-medium-3"></i>-->
<!--            </div>-->
<!--            <input #search (keydown.esc)="onEscEvent()" (keyup)="onSearchKey($event)" (keyup.enter)="onEnter()" [appAutocomplete]="autocomplete"-->
<!--                   [formControl]="control" autofocus class="input" data-search="template-search" placeholder="Explore Apex..." tabindex="0" type="text"/>-->
<!--            <div (click)="toggleSearchOpenClass(false)" class="search-input-close">-->
<!--              <i class="ft-x font-medium-3"></i>-->
<!--            </div>-->
<!--            <app-autocomplete #autocomplete="appAutocomplete">-->
<!--              <ng-template appAutocompleteContent>-->
<!--                <ng-container *ngIf="(listItems | filter: control.value) as result">-->
<!--                  <app-option #searchResults (click)="redirectTo(option.url);" (mouseenter)="removeActiveClass()" *ngFor="let option of result"-->
<!--                              [url]="option.url" [value]="option.name">-->
<!--                    <div class="d-flex align-items-center justify-content-between w-100">-->
<!--                      <div class="d-flex align-items-center justify-content-start">-->
<!--                        <i class="{{option.icon}} mr-2"></i>-->
<!--                        <span>{{ option.name }}</span>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </app-option>-->
<!--                  <app-option *ngIf="!result.length" class="no-result">No results found.</app-option>-->
<!--                </ng-container>-->
<!--              </ng-template>-->
<!--            </app-autocomplete>-->
<!--          </div>-->
<!--        </li>-->
      </ul>
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="logo">
                <a *ngIf="config?.layout.menuPosition === 'Top'" [routerLink]="['/']" class="logo-text">
                  <div class="logo-img"><img [src]="logoUrl" alt="Apex logo" class="logo-img"></div>
                  <span class="text">APEX</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">
<!--          <li class="i18n-dropdown dropdown nav-item mr-2" display="dynamic" ngbDropdown>-->
<!--            <a class="nav-link d-flex align-items-center dropdown-toggle dropdown-language" style="    font-weight: bold;"-->
<!--               data-toggle="dropdown" href="javascript:;" id="dropdown-currency" ngbDropdownToggle matTooltip="choose your currency">-->
<!--              {{currencyKey}}-->
<!--            </a>-->
<!--            <ul class="dropdown-menu" ngbDropdownMenu>-->
<!--              <li *ngFor="let currency of currencies">-->
<!--                <a class="dropdown-item" href="javascript:void(0);" (click)="changeCurrency(currency);"> {{ currency | findCurrencyNewFromCodePipe }}</a>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </li>-->
          <li class="i18n-dropdown dropdown nav-item mr-2" display="dynamic" ngbDropdown>
            <a class="nav-link d-flex align-items-center dropdown-toggle dropdown-language" data-toggle="dropdown" href="javascript:;" id="dropdown-flag"
                matTooltip="choose your language">
              <img [src]="'./assets/img/flags/'+ languageKey + '.png'" alt="flag" class="langimg selected-flag"/>
              {{ languageKey | findLanguageFromKey }}
            </a>
            <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="languagesnavBarDropdown">
              <li *ngFor="let language of languages">
                <a class="dropdown-item" [jhiActiveMenu]="language" href="javascript:void(0);" (click)="changeLanguage(language);collapseNavbar();">
                  <img alt="flag" class="langimg mr-2" [src]="'./assets/img/flags/'+ (language | findFlagFromKey) + '.png'"/>
                  {{ language | findLanguageFromKey }}
                </a>
              </li>
            </ul>
          </li>
          <li class="dropdown nav-item" display="static" ngbDropdown placement="bottom-left">
            <a class="nav-link dropdown-toggle dropdown-notification p-0 mt-2" data-toggle="dropdown" href="javascript:;"
               id="drp-notification" ><i class="ft-bell font-medium-3"></i><span class="notification badge badge-pill badge-danger">0</span></a>
            <ul class="notification-dropdown dropdown-menu dropdown-menu-media dropdown-menu-right m-0 overflow-hidden" ngbDropdownMenu>
              <li class="dropdown-menu-header">
                <div class="dropdown-header d-flex justify-content-between m-0 px-3 py-2 white bg-primary">
                  <div class="d-flex">
                    <i class="ft-bell font-medium-3 d-flex align-items-center mr-2"></i><span class="noti-title">0 New Notification</span>
                  </div>
                  <span class="text-bold-400 cursor-pointer" translate="navbar.MARK_ALL_AS_READ"></span>
                </div>
              </li>
              <li class="dropdown-menu-footer">
                <div class="noti-footer text-center cursor-pointer primary border-top text-bold-400 py-1">
                  <span translate="navbar.READ_ALL_NOTIFICATIONS"></span>
                </div>
              </li>
            </ul>
          </li>
          <li class="dropdown nav-item mr-1" display="static" ngbDropdown placement="bottom-left">
            <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end" data-toggle="dropdown"
               href="javascript:;" id="dropdownBasic2" >
              <div class="user d-md-flex d-none mr-2" style="    margin-bottom: 10px;">
                <span class="text-right" >{{profile?.firstName}} {{profile?.lastName}}</span>
              </div>
              <img *ngIf="!userPhoto" alt="avatar" class="header-photo" height="35" src="assets/img/moorishHome.png" width="35"/>
              <img *ngIf="userPhoto" height="35" width="35" class="header-photo" [src]="'data:' + userPhotoContentType + ';base64,' + userPhoto" alt="profile image"/>
            </a>
            <div aria-labelledby="dropdownBasic2" class="dropdown-menu text-left dropdown-menu-right m-0 pb-0" ngbDropdownMenu>
              <a class="dropdown-item" routerLink="/page/profile">
                <div class="d-flex align-items-center">
                  <i class="ft-edit mr-2"></i><span translate="navbar.EDIT_PROFILE"></span>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="logout()">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span translate="navbar.LOGOUT"></span>
                </div>
              </a>
            </div>
          </li>
<!--          <li class="nav-item d-none d-lg-block mr-2 mt-1">-->
<!--            <a class="nav-link notification-sidebar-toggle" (click)="toggleNotificationSidebar();"><i-->
<!--              class="ft-align-right font-medium-3"></i></a>-->
<!--          </li>-->
        </ul>
      </div>
    </div>
  </div>
</nav>
