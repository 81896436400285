<!-- Sidebar Header starts -->
<div *ngIf="config?.layout.menuPosition === 'Side'" class="sidebar-header">
  <div class="logo clearfix">
    <a [routerLink]="['/']" class="logo-text float-logo">
      <div class="logo-img">
        <img style="width: 40px;" src="../../../assets/img/moorishHome.png"/>
      </div>
      <!--<span style="color: brown;">Deal</span>-->
      <!--<span style="color: black;">To</span>-->
      <!--<span style="color: blue;">Book</span>-->
      <span class="text align-middle">{{'dealtobook' | translate}}</span>
    </a>
    <a (click)="toggleSidebar()" class="nav-toggle d-none d-lg-none d-xl-block" href="javascript:;"
       id="sidebarToggle">
      <i #toggleIcon [ngClass]="{
        'ft-toggle-left': config.layout.sidebar.collapsed,
        'ft-toggle-right': !config.layout.sidebar.collapsed
      }" class="toggle-icon"></i>
    </a>
    <a (click)="CloseSidebar()" class="nav-close d-block d-lg-block d-xl-none" href="javascript:;" id="sidebarClose">
      <i class="ft-x"></i>
    </a>
  </div>
</div>
<!-- Sidebar Header Ends -->

<!-- Sidebar Content starts -->
<div [disabled]="!perfectScrollbarEnable" [perfectScrollbar] class="sidebar-content main-menu-content">
  <div class="nav-container">
    <ul class="navigation" style="background-color: #f0f8ff14;">
      <li>
        <a [routerLink]="['/']">
          <i class="mdi mdi-home" aria-hidden="true"></i>
          <span class="menu-title">{{'menu.home' | translate}}</span>
          <span> </span>
        </a>
      </li>
    </ul>
    <ul class="navigation">
      <li>
        <a [routerLink]="['/page/profile']">
          <i class="mdi mdi-clientCantact" aria-hidden="true"></i>
          <span class="menu-title">{{'menu.profile' | translate}}</span>
          <span> </span>
        </a>
      </li>
    </ul>
    <ul appSidebarDropdown class="navigation">
      <!-- First level menu -->
      <li *ngFor="let menuItem of menuItems" [hasSub]="menuItem.classs==='has-sub'" [ngClass]="{'has-sub': menuItem.classs==='has-sub'}" [parent]="menuItem.title"
          [path]="menuItem.path" [routerLinkActiveOptions]="{exact: false}" [routerLinkActive]="menuItem.submenu.length != 0 ? 'open' : 'active'" appSidebarlink
          level="{{level + 1}}">
        <a *ngIf="menuItem.submenu.length > 0 && !menuItem.isExternalLink" appSidebarAnchorToggle>
          <i [ngClass]="[menuItem.icon]"></i>
          <span class="menu-title" [appTranslate]="menuItem.title"> </span>
          <span *ngIf="menuItem.badge && menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
        </a>
        <a *ngIf="menuItem.submenu && menuItem.submenu.length === 0 && !menuItem.isExternalLink" appSidebarAnchorToggle routerLink="{{'/page/'+menuItem.path}}">
          <i [ngClass]="[menuItem.icon]"></i>
          <span class="menu-title">{{menuItem.title | translate }}</span>
          <span *ngIf="menuItem.badge && menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
        </a>
        <a *ngIf="menuItem.isExternalLink" [href]="[menuItem.path]" target="_blank">
          <i [ngClass]="[menuItem.icon]"></i>
          <span class="menu-title">{{menuItem.title | translate }}</span>
          <span *ngIf="menuItem.badge && menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
        </a>
        <!-- Second level menu -->
        <ul *ngIf=" menuItem.submenu.length > 0 " appSidebarDropdown class="menu-content">
          <li *ngFor="let menuSubItem of menuItem.submenu" [hasSub]="menuSubItem.classs==='has-sub'" [ngClass]="{'has-sub': menuSubItem.classs==='has-sub'}"
              [parent]="menuItem.title" [path]="menuSubItem.path" [routerLinkActive]="menuSubItem.submenu && menuSubItem.submenu.length != 0 ? 'open' : 'active'"
              appSidebarlink level="{{level + 2}}">
            <a *ngIf="menuSubItem.submenu && menuSubItem.submenu.length > 0 && !menuSubItem.isExternalLink" appSidebarAnchorToggle>
              <i [ngClass]="[menuSubItem.icon]"></i>
              <span class="menu-title">{{menuSubItem.title | translate }}</span>
              <span *ngIf="menuSubItem.badge && menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
            </a>
            <a *ngIf="menuSubItem.submenu && menuSubItem.submenu.length === 0 && !menuSubItem.isExternalLink" appSidebarAnchorToggle routerLink="{{'/page/'+menuSubItem.path}}">
              <i [ngClass]="[menuSubItem.icon]"></i>
              <span class="menu-title">{{menuSubItem.title | translate }}</span>
              <span *ngIf="menuSubItem.badge && menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
            </a>
            <a *ngIf="menuSubItem.isExternalLink" routerLink="{{'/page/'+menuSubItem.path}}" target="_blank">
              <i [ngClass]="[menuSubItem.icon]"></i>
              <span class="menu-title">{{menuSubItem.title | translate }}</span>
              <span *ngIf="menuSubItem.badge && menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
            </a>
            <!-- Third level menu -->
            <ul *ngIf="menuSubItem.submenu && menuSubItem.submenu.length > 0" appSidebarDropdown class="menu-content">
              <li *ngFor="let menuSubsubItem of menuSubItem.submenu" [hasSub]="false" [ngClass]="config.layout.menuPosition === 'Side' ? menuSubsubItem.classs : ''"
                  [parent]="menuItem.title" [path]="menuSubsubItem.path" [routerLinkActiveOptions]="{exact: true}" appSidebarlink level="{{level + 3}}"
                  routerLinkActive="active">
                <a *ngIf="!menuSubsubItem.isExternalLink; else externalSubSubLinkBlock" appSidebarAnchorToggle routerLink="{{'/page/'+menuSubsubItem.path}}">
                  <i [ngClass]="[menuSubsubItem.icon]"></i>
                  <span class="menu-title">{{menuSubsubItem.title | translate }}</span>
                  <span *ngIf="menuSubsubItem.badge && menuSubsubItem.badge != '' " [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                </a>
                <ng-template #externalSubSubLinkBlock>
                  <a routerLink="{{'/page/'+menuSubsubItem.path}}" target="_blank">
                    <i [ngClass]="[menuSubsubItem.icon]"></i>
                    <span class="menu-title">{{menuSubsubItem.title | translate }}</span>
                    <span *ngIf="menuSubsubItem.badge && menuSubsubItem.badge != '' " [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                  </a>
                </ng-template>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <ul class="navigation" style="background-color: #f0f8ff14;">
      <li>
        <a [routerLink]="['/page/work-in-progress']">
          <i class="mdi mdi-chart-areaspline" aria-hidden="true"></i>
          <span class="menu-title">{{'menu.statistic' | translate}}</span>
          <span> </span>
        </a>
      </li>
    </ul>
  </div>
</div>
<!-- Sidebar Content Ends -->
