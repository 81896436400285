import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {createRequestOption} from 'app/core/request/request-util';
import {CountryIdName} from '../../entities/country/countryIdName.model';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {ICountry} from "../../entities/country/country.model";

type EntityResponseType = HttpResponse<ICountry>;
type EntityArrayResponseType = HttpResponse<ICountry[]>;

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  private resourceUrl = '/dealsetting/api/countries';

  constructor(private http: HttpClient) {
  }

  create(country: ICountry): Observable<EntityResponseType> {
    const copy = this.convert(country);
    return this.http.post<ICountry>(this.resourceUrl, copy, {observe: 'response'});
  }

  update(country: ICountry): Observable<EntityResponseType> {
    const copy = this.convert(country);
    return this.http.put<ICountry>(this.resourceUrl, copy, {observe: 'response'});
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<ICountry>(`${this.resourceUrl}/${id}`, {observe: 'response'});
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<ICountry[]>(this.resourceUrl, {params: options, observe: 'response'});
  }

  queryGeoNames(): Observable<any> {
    return this.http.get('/dealsetting/api/countries/uri').pipe(map(res => this.convertResponse2(res)));
  }

  queryCountryName(): Observable<HttpResponse<any>> {
    return this.http.get('/dealsetting/api/countries/uri/idName').pipe(map(res => this.convertResponse2(res)));
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, {observe: 'response'});
  }

  getAllCountry(): Observable<any> {
    return this.http.get('/dealsetting/api/countries/uri', {observe: 'response'});
  }

  private convertResponse2(res: any): HttpResponse<any> {
    const jsonResponse = res;
    const result = [];
    for (let i = 0; i < jsonResponse.length; i++) {
      result.push(this.convertItemFromServer2(jsonResponse[i]));
    }
    return new HttpResponse({body: result, headers: res.headers, status: res.status});
  }

  /**
   * Convert a returned JSON object to Country.
   */
  private convertItemFromServer2(json: any): ICountry {
    return Object.assign(new CountryIdName(0, ''), json);
  }

  /**
   * Convert a Country to a JSON which can be sent to the server.
   */
  private convert(country: ICountry): ICountry {
    const copy: ICountry = Object.assign({}, country);
    return copy;
  }
}
