import {Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CurrentTierService {
  private tier?: any;
  private tier$: ReplaySubject<any> = new ReplaySubject<any>();

  getTier() {
    return this.tier;
  }

  setTier(tier: any) {
    this.tier = tier;
    this.tier$.next(tier);
  }

  /**
   * same as setTier, but without triggering observable
   */
  updateTier(tier: any) {
    this.tier = tier;
  }

  getTierObservable() {
    return this.tier$;
  }
}
