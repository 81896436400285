import {APP_INITIALIZER, LOCALE_ID, NgModule} from "@angular/core";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

import {AngularFireModule} from "@angular/fire";
import {AngularFireAuthModule} from "@angular/fire/auth";

import {NgbDateAdapter, NgbDatepickerConfig, NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {AgmCoreModule} from "@agm/core";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {NgxSpinnerModule} from 'ngx-spinner';
import {BrowserModule, Title} from '@angular/platform-browser';

import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {NgbDateDayjsAdapter} from './config/datepicker-adapter';

import {AppRoutingModule} from "./app-routing.module";
import {SharedModule} from "./shared/shared.module";
import {AppComponent} from "./app.component";
import {FullLayoutComponent} from "./layouts/full/full-layout.component";

import {WINDOW_PROVIDERS} from './shared/services/window.service';
import {translatePartialLoader} from './config/translation.config';
import {httpInterceptorProviders} from './core/interceptor';
import {ApplicationConfigService} from 'app/core/config/application-config.service';
import {registerLocaleData} from '@angular/common';
import locale from '@angular/common/locales/en';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import {NgxWebstorageModule, SessionStorageService} from 'ngx-webstorage';
import {SERVER_API_URL} from './app.constants';
import {KeycloakAngularModule, KeycloakService} from "keycloak-angular";
import {initializeKeycloak} from "app/core/init/keycloak-init.factory";
import {FindLanguageFromKeyPipe} from "./shared/language/find-language-from-key.pipe";
import {CookieService} from "ngx-cookie-service";
import {MAT_DATE_LOCALE} from "@angular/material/core";

const firebaseConfig = {
  apiKey: "YOUR_API_KEY", //YOUR_API_KEY
  authDomain: "YOUR_AUTH_DOMAIN", //YOUR_AUTH_DOMAIN
  databaseURL: "YOUR_DATABASE_URL", //YOUR_DATABASE_URL
  projectId: "YOUR_PROJECT_ID", //YOUR_PROJECT_ID
  storageBucket: "YOUR_STORAGE_BUCKET", //YOUR_STORAGE_BUCKET
  messagingSenderId: "YOUR_MESSAGING_SENDER_ID", //YOUR_MESSAGING_SENDER_ID
  appId: "YOUR_APP_ID", //YOUR_APP_ID
  measurementId: "YOUR_MEASUREMENT_ID" //YOUR_MEASUREMENT_ID
};


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

@NgModule({
  declarations: [AppComponent, FullLayoutComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    NgbModule,
    KeycloakAngularModule,
    NgxSpinnerModule,
    NgxWebstorageModule.forRoot({prefix: '', separator: '-', caseSensitive: true}),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translatePartialLoader,
        deps: [HttpClient],
      },
      // missingTranslationHandler: {
      //   provide: MissingTranslationHandler,
      //   useFactory: missingTranslationHandler,
      // },
    }),
    AgmCoreModule.forRoot({
      apiKey: "YOUR_GOOGLE_MAP_API_KEY"
    }),
    PerfectScrollbarModule
  ],
  providers: [
    Title,
    {provide: LOCALE_ID, useValue: 'en'},
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
    {provide: NgbDateAdapter, useClass: NgbDateDayjsAdapter},
    {provide: APP_INITIALIZER, useFactory: initializeKeycloak, multi: true, deps: [KeycloakService]},
    WINDOW_PROVIDERS,
    httpInterceptorProviders,
    FindLanguageFromKeyPipe,
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    applicationConfigService: ApplicationConfigService,
    // iconLibrary: FaIconLibrary,
    dpConfig: NgbDatepickerConfig,
    translateService: TranslateService,
    sessionStorageService: SessionStorageService
  ) {
    applicationConfigService.setEndpointPrefix(SERVER_API_URL);
    registerLocaleData(locale);
    // iconLibrary.addIcons(...fontAwesomeIcons);
    dpConfig.minDate = {year: dayjs().subtract(100, 'year').year(), month: 1, day: 1};
    translateService.setDefaultLang('en');
    // if user have changed language and navigates away from the application and back to the application then use previously choosed language
    const langKey = sessionStorageService.retrieve('locale') ? sessionStorageService.retrieve('locale') : 'en';
    translateService.use(langKey);
  }
}
