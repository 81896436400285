import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';
import { AccountService } from 'app/core/auth/account.service';
import { Subscription } from 'rxjs';
import {LoginService} from "../../login/login.service";
import {LANGUAGES} from "../../config/language.constants";
import {TranslateService} from "@ngx-translate/core";

declare let $: any;
declare let window: any;
declare let document: any;

@Component({
  selector: 'jhi-nav-bar-register',
  templateUrl: './nav-bar.html',
  styleUrls: ['./navbar.scss'],
})
export class NavBarRegisterComponent implements OnInit, OnDestroy {
  inProduction?: boolean;
  isNavbarCollapsed = true;
  languages = LANGUAGES;
  swaggerEnabled?: boolean;
  version: string;
  account: any;
  accountSubscription?: Subscription;
  languageKey = '';

  constructor(
    private loginService: LoginService,
     private sessionStorage: SessionStorageService,
    private accountService: AccountService,
    private translate: TranslateService,
    // private loginModalService: LoginModalService,
    // private profileService: ProfileService,
    // private languageService:LanguageService,
    private router: Router
  ) {
    // this.version = VERSION ? (VERSION.toLowerCase().startsWith('v') ? VERSION : 'v' + VERSION) : '';
  }

  ngOnInit(): void {
    // this.profileService.getProfileInfo().subscribe(profileInfo => {
    //   this.inProduction = profileInfo.inProduction;
    //   this.swaggerEnabled = profileInfo.swaggerEnabled;
    // });
    this.languageKey = this.sessionStorage.retrieve('locale');
    if (!this.languageKey) {
      this.languageKey = 'en';
    }
  }

  changeLanguage(languageKey: string): void {
    this.sessionStorage.store('locale', languageKey);
    this.translate.use(languageKey);
    this.languageKey = languageKey;
  }

  collapseNavbar(): void {
    this.isNavbarCollapsed = true;
  }

  toggleNavbar(): void {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }

  ngOnDestroy(): void {
    if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
    }
  }
}
