import {Component, OnDestroy, OnInit, Renderer2, RendererFactory2} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {filter, takeUntil} from 'rxjs/operators';
import {FindLanguageFromKeyPipe} from "./shared/language/find-language-from-key.pipe";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import * as dayjs from 'dayjs';
import {KeycloakEvent, KeycloakEventType, KeycloakService} from "keycloak-angular";
import {ExpireSessionDialogComponent} from "./shared/component/expire-session/expire-session-dialog.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Account} from "./core/user/account.model";
import {AccountService} from "./core/auth/account.service";
import {CurrentCurrencyService} from "./shared/services/current-currency.service";
import {SessionStorageService} from "ngx-webstorage";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  private renderer: Renderer2;
  protected _onDestroy = new Subject<void>();

  constructor(private router: Router,
              private principal: AccountService,
              private findLanguageFromKeyPipe: FindLanguageFromKeyPipe,
              private translateService: TranslateService,
              private currentCurrencyService: CurrentCurrencyService,
              private sessionStorage: SessionStorageService,
              private modalService: NgbModal,
              private keycloakService: KeycloakService,
              rootRenderer: RendererFactory2
  ) {
    this.renderer = rootRenderer.createRenderer(document.querySelector('html'), null);
  }

  ngOnInit() {
    this.subscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this._onDestroy)
    ).subscribe(() => window.scrollTo(0, 0));
    console.log("date", new Date())
    this.keycloakService.keycloakEvents$.subscribe((event: KeycloakEvent) => {
      switch (event.type) {
        case KeycloakEventType.OnAuthRefreshSuccess:
          console.log("OnAuthRefreshSuccess", new Date())
          this.principal.identity(true)
          break;
        case KeycloakEventType.OnAuthRefreshError:
          // Handle refresh token expiration or refresh error
          // console.log("OnAuthRefreshError", new Date())
           this.modalService.open(ExpireSessionDialogComponent, {size: 'lg', backdrop: 'static'});
          break;
        case KeycloakEventType.OnAuthLogout:
            this.modalService.open(ExpireSessionDialogComponent, {size: 'lg', backdrop: 'static'});
          break;

        case KeycloakEventType.OnTokenExpired:
          break;
// console.log("OnTokenExpired", new Date())
          // Handle access token expiration
          break;
        // other cases...
      }
      if (event.type === KeycloakEventType.OnTokenExpired) {

        // this.keycloakService.logout('/login');
      }

    });
    this.principal.identity().pipe(takeUntil(this._onDestroy)).subscribe((account: Account | null) => {
      // this.currentCurrencyService.setCurrency(CU)
    })

    if (this.sessionStorage.retrieve('currency')) {
      this.currentCurrencyService.setCurrency(this.sessionStorage.retrieve('currency'))
    }


    this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
      dayjs.locale(langChangeEvent.lang);
      this.renderer.setAttribute(document.querySelector('html'), 'lang', langChangeEvent.lang);

      this.updatePageDirection();
    });
  }

  private updatePageDirection(): void {
    this.renderer.setAttribute(
      document.querySelector('html'),
      'dir',
      this.findLanguageFromKeyPipe.isRTL(this.translateService.currentLang) ? 'rtl' : 'ltr'
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


}
