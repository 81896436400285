import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AccessPermission } from 'app/core/auth/access.model';
import { AccountService } from 'app/core/auth/account.service';

/**
 * @whatItDoes Conditionally includes an HTML element if current user has any
 * of the authorities passed as the `expression`.
 *
 * @howToUse
 * ```
 *     <some-element *jhiHasAnyAuthority="'ROLE_ADMIN'">...</some-element>
 *
 *     <some-element *jhiHasAnyAuthority="['ROLE_ADMIN', 'ROLE_USER']">...</some-element>
 * ```
 */
@Directive({
  selector: '[hasDealAuthority]',
})
export class HasDealAuthorityDirective {
  private authorities?: AccessPermission[];

  constructor(private principal: AccountService, private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef) {}

  @Input()
  set hasDealAuthority(accessPermission: AccessPermission[]) {
    this.authorities = accessPermission;
    this.updateView();
    // Get notified each time authentication state changes.
    this.principal.getAuthenticationState().subscribe(() => this.updateView());
  }

  private updateView(): void {
    this.principal.hasDealAuthority(this.authorities || []).then(result => {
      this.viewContainerRef.clear();
      if (result) {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      }
    });
  }
}
