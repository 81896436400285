import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
// import {createRequestOption} from 'app/core/request/request-util';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {createRequestOption} from 'app/core/request/request-util';
import {ServiceType, ServiceTypeGroup} from 'app/shared/model/service-type.model';

@Injectable({
  providedIn: 'root',
})
export class ServiceTypeService {
  private resourceUrl = '/dealsetting/api/service-types';

  constructor(private http: HttpClient) {
  }

  getAllByGroup(group: string, lang?: string): Observable<HttpResponse<ServiceType[]>> {
    const options = createRequestOption();
    return this.http.get<ServiceType[]>(this.resourceUrl + '/' + group + '?lang=' + (!lang ? 'en' : lang), {
      params: options,
      observe: 'response',
    });
  }

  getAll(lang?: string): Observable<HttpResponse<ServiceTypeGroup[]>> {
    const options = createRequestOption();
    return this.http.get<ServiceTypeGroup[]>(this.resourceUrl + '?lang=' + (!lang ? 'en' : lang), {params: options, observe: 'response'});
  }
}
