import {Component} from '@angular/core';

import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs';
import {HttpResponse} from '@angular/common/http';
import {EventManager} from "../../../core/util/event-manager.service";

@Component({
  selector: 'deal-delete-dialog',
  templateUrl: './delete-dialog.component.html',
})
export class DeleteDialogComponent<T> {
  entityListModification?: string;
  entityService?: Observable<HttpResponse<T>>;

  constructor(public activeModal: NgbActiveModal, private eventManager: EventManager) {
  }

  clear() {
    this.activeModal.dismiss('cancel');
  }

  confirmDelete() {
    this.entityService!.subscribe(() => {
      this.eventManager.broadcast({
        name: this.entityListModification!,
        content: 'Deleted an element',
      });
      this.activeModal.dismiss(true);
    });
  }
}
