import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({name: 'selectOptionsTranslate'})
export class SelectOptionsTranslatePipe implements PipeTransform {
  constructor(public translateService: TranslateService) {
  }

  transform(items: Array<any>): Array<any> {
    for (const item of items) {
      item.name = this.translateService.instant(item.name);
    }
    return items;
  }
}
