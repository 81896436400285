import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class CurrentCurrencyService {
  private currentCurrency: string;
  private currency$: Subject<string | null> = new Subject<string | null>();


  setCurrency(currency: string | null) {
    this.currency$.next(currency);
    this.currentCurrency = currency;
  }

  getCurrencyObservable(): Observable<string | null> {
    return this.currency$.asObservable();
  }

  public getCurrentCurrency(): string {
    return this.currentCurrency;
  }
}
