<form name="deleteForm">
  <div class="modal-header">
    <div class="fl-popin__title"> Tier status comments</div>
    <button (click)="clear()" aria-hidden="true" class="close" data-dismiss="modal" type="button">&times;
    </button>
  </div>
  <div class="modal-body">
    <deal-alert-error></deal-alert-error>
    <span class="form-control-label">Comment list</span>
    <table class="table " style="border-style: solid;">
      <thead>
      <tr>
        <th style="padding: 0.15rem 1rem;">User</th>
        <th style="padding: 0.15rem 1rem;">Comment</th>
        <th style="padding: 0.15rem 1rem;">Date</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let comm of comments ; let i = index">
        <td>{{comm.createdBy}}</td>
        <td>{{comm.comment}}</td>
        <td>{{comm.createdDate | date:'dd/MM/y, h:mm a'}}</td>
      </tr>
      </tbody>
    </table>
    <div class="form-group col-md-12">
      <label class="form-control-label" for="commentaire">add new comment</label>
      <textarea [(ngModel)]="comment" class="form-control" id="commentaire" name="commentaire"
                placeholder="your comment" type="text"></textarea>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="clear()" mat-raised-button type="reset" style=";margin-right: 10px">
      <i class="mdi mdi-18px mdi-cancel"></i>&nbsp;
      <span translate="entity.action.cancel"></span>
    </button>
    <button color="primary" (click)="addComment()" mat-raised-button type="submit" style="margin-right: 10px">
      <i class="mdi mdi-18px mdi-save"></i>&nbsp;
      <span translate="entity.action.save"></span>
    </button>

  </div>
</form>
