import {Injectable} from '@angular/core';
import {Account} from 'app/core/user/account.model';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountObservableService {
  accountObservable: Subject<Account | null> = new Subject<Account | null>();
  currentProfile: Subject<any | null> = new Subject<any | null>();


  sendCurrentProfile(account:any): void {
    this.accountObservable.next(account);
  }

  getCurrentProfile(): Observable<any | null> {
    return this.accountObservable.asObservable();
  }

}
