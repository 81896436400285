import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'findLanguageFromKey'})
export class FindLanguageFromKeyPipe implements PipeTransform {
  private languages: { [key: string]: { name: string; rtl?: boolean } } = {
    ar: {name: 'العربية', rtl: true},
    en: {name: 'English'},
    fr: {name: 'Français'},
    // jhipster-needle-i18n-language-key-pipe - JHipster will add/remove languages in this object
  };

  transform(lang: string): string {
    return this.languages[lang].name;
  }

  isRTL(lang: string): boolean {
    return Boolean(this.languages[lang].rtl);
  }
}

@Pipe({ name: 'findFlagFromKey' })
export class FindFlagFromKeyPipe implements PipeTransform {
  private languages: { [key: string]: { icon: string; rtl?: boolean } } = {
    ar: { icon: 'ar', rtl: true },
    en: { icon: 'en' },
    fr: { icon: 'fr' },
    // jhipster-needle-i18n-language-key-pipe - JHipster will add/remove languages in this object
  };

  transform(lang: string): string {
    return this.languages[lang].icon;
  }

  isRTL(lang: string): boolean {
    return Boolean(this.languages[lang].rtl);
  }
}
