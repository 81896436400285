import {Injectable} from '@angular/core';
import {SessionStorageService} from 'ngx-webstorage';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable, of, Subject} from 'rxjs';

import {SERVER_API_URL} from 'app/app.constants';
import {AccessPermission} from 'app/core/auth/access.model';
import {Account} from 'app/core/user/account.model';
import {AuthServerProvider} from 'app/core/auth/auth-jwt.service';
import {catchError, map} from 'rxjs/operators';
import {TranslateService} from "@ngx-translate/core";
import {ApplicationConfigService} from "../config/application-config.service";
import {AccountObservableService} from "../../shared/services/accountObservable.service";
import {KeycloakService} from "keycloak-angular";

@Injectable({providedIn: 'root'})
export class AccountService {
  userIdentity: Account | null = null;
  authenticated = false;
  authenticationState = new Subject<any>();
  accountCache$?: Observable<Account | null>;
  resourceUrl = SERVER_API_URL + '/dealsecurity/api/authorise';

  constructor(
    private translateService: TranslateService,
    private sessionStorage: SessionStorageService,
    private applicationConfigService: ApplicationConfigService,
    private authServerProvider: AuthServerProvider,
    private keycloakService: KeycloakService,
    private accountObservableService: AccountObservableService,
    private http: HttpClient
  ) {
  }

  fetch(): Observable<Account> {
    // return this.http.get<Account>(SERVER_API_URL + 'dealsecurity/' + 'api/account');
    return this.http.get<Account>(this.applicationConfigService.getEndpointFor('api/account', 'dealsecurity'));
  }

  save(account: Account): Observable<Account> {
    return this.http.post<Account>(SERVER_API_URL + 'dealsecurity/' + 'api/account', account);
  }

  authenticate(identity: Account | null) {
    this.userIdentity = identity;
    this.authenticated = identity !== null;
    this.authenticationState.next(this.userIdentity);
  }

  hasAnyAuthority(authorities: string[] | string): boolean {
    if (!this.authenticated || !this.userIdentity || !this.userIdentity.roleName) {
      return false;
    }

    for (let i = 0; i < authorities.length; i++) {
      if (this.userIdentity.roleName === authorities[i]) {
        return true;
      }
    }

    return false;
  }

  hasDealAuthority(authorities: AccessPermission[]): Promise<boolean> {
    return Promise.resolve(this.hasDealAuthorityDirect(authorities));
  }

  hasDealAuthorityDirect(authorities: AccessPermission[]) {
    if (!this.authenticated || !this.userIdentity || !this.userIdentity.roleName) {
      return false;
    }

    for (let i = 0; i < authorities.length; i++) {
      // if (this.userIdentity.roleName === authorities[i].roleName) {
      const permissionIndex = this.userIdentity.permissionsAction!.findIndex(p => {
        return (
          p.url === authorities[i].permission && (p.simplePermission || p.action!.findIndex(a => a === authorities[i].action) !== -1)
        );
      });
      if (permissionIndex !== -1) {
        return true;
      }
    }

    return false;
  }

  hasAuthority(url: string, method: string): Observable<HttpResponse<Boolean>> {
    const params: URLSearchParams = new URLSearchParams();
    params.set('url', url);
    params.set('methode', method);
    return this.http.get<Boolean>(`${this.resourceUrl}/?url=` + url + '&method=' + method, {observe: 'response'});
  }

  identity(force?: boolean): Observable<Account> {
    if (!this.userIdentity || force === true) {
      this.accountCache$ = this.fetch().pipe(
        map((account: Account) => {
          if (account) {
            this.userIdentity = account;
            this.authenticated = true;

            if (this.authServerProvider.getTierPublicId() === null) {
              this.authServerProvider.storeTierPublicId(this.userIdentity.tierPublicId!, false);
              this.authServerProvider.storeEmail(this.userIdentity.email, false);
              this.authServerProvider.storeProfileData(this.userIdentity, false);
              this.accountObservableService.sendCurrentProfile(this.userIdentity);
              this.authServerProvider.storeUserPhoto(this.userIdentity.userPhoto, this.userIdentity.userPhotoContentType, false);
              this.authServerProvider.storeUserPublicId(this.userIdentity.userPublicId!, false);
              this.authServerProvider.storeRolePublicId(this.userIdentity.rolePublicId!, false);
            }

            // After retrieve the account info, the language will be changed to
            // the user's preferred language configured in the account setting
            if (this.userIdentity.langKey) {
              const langKey = this.sessionStorage.retrieve('locale') || this.userIdentity.langKey;
              this.translateService.use(account.langKey);
            }
          } else {
            this.userIdentity = null;
            this.authenticated = false;
            // this.authServerProvider.loginWithPopup();
          }
          this.authenticationState.next(this.userIdentity);
          return this.userIdentity!;
        }),
        catchError(() => {
          this.userIdentity = null;
          this.authenticated = false;
          this.authenticationState.next(this.userIdentity);
          return of(this.userIdentity);
        })
      );
      return this.accountCache$;
    }
    return of(this.userIdentity);

  }

  isAuthenticated(): boolean {
    return this.authenticated;
  }

  isIdentityResolved(): boolean {
    return this.userIdentity !== undefined;
  }

  getAuthenticationState(): Observable<any> {
    return this.authenticationState.asObservable();
  }

  getAccount() {
    return this.accountCache$;
  }

  // initAccount() {
  //   this.accountCache$ = null;
  // }

  getImageUrl() {
    // return this.isIdentityResolved() ? this.userIdentity.imageUrl : null;
  }
}
