import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ErrorHandlerInterceptor} from 'app/core/interceptor/error-handler.interceptor';
import {NotificationInterceptor} from 'app/core/interceptor/notification.interceptor';

export const httpInterceptorProviders = [
  // {
  //   provide: HTTP_INTERCEPTORS,
  //   useClass: AuthExpiredInterceptor,
  //   multi: true,
  // },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: NotificationInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorHandlerInterceptor,
    multi: true,
  }
];
