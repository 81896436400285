<form (ngSubmit)="confirmLogin()" name="deleteForm">
  <div class="modal-header">
    <h4 class="modal-title" data-cy="cityDeleteDialogHeading">Expiration session</h4>

    <button (click)="cancel()" aria-hidden="true" class="close" data-dismiss="modal" type="button">&times;</button>
  </div>

  <div class="modal-body">
    <p  id="jhi-delete-city-heading" >
      Your session is expired you have to login?
    </p>
    <button class="btn btn-danger" data-cy="entityConfirmDeleteButton" id="jhi-confirm-delete-city" type="submit">
      <fa-icon icon="times"></fa-icon>&nbsp;<span>Login</span>
    </button>
  </div>
</form>
