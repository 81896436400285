import {KeycloakService} from "keycloak-angular";
import {environment} from "../../../environments/environment";

export function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.KEYCLOAK_URL,
        realm: environment.KEYCLOAK_REALM,
        clientId: environment.KEYCLOAK_CLIENT_ID,
      }, initOptions: {
        // onLoad: 'login-required',
        checkLoginIframe: true
      },
    });
}
