import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  private toggleSidebar = new Subject<boolean>(); // small screen
  // Observable
  toggleSidebar$ = this.toggleSidebar.asObservable();
  private overlaySidebarToggle = new Subject<boolean>();
  overlaySidebarToggle$ = this.overlaySidebarToggle.asObservable();
  private toggleNotiSidebar = new Subject<boolean>();
  toggleNotiSidebar$ = this.toggleNotiSidebar.asObservable();
  private toggleBaskSidebar = new Subject<boolean>(); // small screen
  private toggleBaskSelection = new Subject<any>(); // small screen
  toggleBaskSidebar$ = this.toggleBaskSidebar.asObservable();
  toggleBaskSelection$ = this.toggleBaskSelection.asObservable();

  toggleSidebarSmallScreen(toggle: boolean) {
    this.toggleSidebar.next(toggle);
  }

  overlaySidebartoggle(toggle: boolean) {
    this.overlaySidebarToggle.next(toggle);
  }

  toggleNotificationSidebar(toggle: boolean) {
    this.toggleNotiSidebar.next(toggle);
  }

  toggleBasketSidebar(toggle: boolean, accomodationSelected?: any) {
    this.toggleBaskSidebar.next(toggle);
    if (accomodationSelected) {
      this.toggleBaskSelection.next(accomodationSelected);
    }
  }

}
