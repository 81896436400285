import {Component, OnInit} from '@angular/core';

import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {EventManager} from "../../../core/util/event-manager.service";
import {TierService} from "../../services/tier.service";

@Component({
  selector: 'deal-tier-comment-dialog',
  templateUrl: './comment-dialog.component.html',
})
export class TierCommentDialogComponent implements OnInit {

  tierPublicId?: string;

  public comment?: string;
  public comments?: [];

  constructor(public activeModal: NgbActiveModal, private eventManager: EventManager, private router: Router,
              public tierService: TierService) {
  }

  clear() {
    this.activeModal.dismiss('cancel');
  }

  addComment() {
    if (this.comment)
      this.tierService.addComment(this.tierPublicId, this.comment).subscribe(response => {
        this.eventManager.broadcast({
          name: 'tierListModification',
          content: response.body,
        });
        this.activeModal.dismiss(true);
      });
  }

  getAllComment() {
    this.tierService.getComment(this.tierPublicId).subscribe(response => {
      this.comments = response.body;
    });
  }

  ngOnInit(): void {
    this.getAllComment();
  }
}
