import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {OverlayModule} from '@angular/cdk/overlay';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {PerfectScrollbarModule} from "ngx-perfect-scrollbar";
import {ClickOutsideModule} from 'ng-click-outside';

import {AutocompleteModule} from './component/autocomplete/autocomplete.module';
import {PipeModule} from 'app/shared/pipes/pipe.module';
//COMPONENTS
import {FooterComponent} from "./footer/footer.component";
import {NavbarComponent} from "./navbar/navbar.component";
import {HorizontalMenuComponent} from './horizontal-menu/horizontal-menu.component';
import {VerticalMenuComponent} from "./vertical-menu/vertical-menu.component";
import {CustomizerComponent} from './customizer/customizer.component';
import {NotificationSidebarComponent} from './notification-sidebar/notification-sidebar.component';
//DIRECTIVES
import {ToggleFullscreenDirective} from "./directives/toggle-fullscreen.directive";
import {SidebarLinkDirective} from './directives/sidebar-link.directive';
import {SidebarDropdownDirective} from './directives/sidebar-dropdown.directive';
import {SidebarAnchorToggleDirective} from './directives/sidebar-anchor-toggle.directive';
import {SidebarDirective} from './directives/sidebar.directive';
import {TopMenuDirective} from './directives/topmenu.directive';
import {TopMenuLinkDirective} from './directives/topmenu-link.directive';
import {TopMenuDropdownDirective} from './directives/topmenu-dropdown.directive';
import {TopMenuAnchorToggleDirective} from './directives/topmenu-anchor-toggle.directive';
import {MaterialModule} from "./material.module";
import {ActivationDialogComponent} from "./component/validate-popup/activation-dialog.component";
import {DeleteDialogComponent} from "./component/delete-popup/delete-dialog.component";
import {AlertComponent} from "./alert/alert.component";
import {AlertErrorComponent} from "./alert/alert-error.component";
import {HasAnyAuthorityDirective} from "./auth/has-any-authority.directive";
import {FindFlagFromKeyPipe, FindLanguageFromKeyPipe} from "./language/find-language-from-key.pipe";
import {TranslateDirective} from "./language/translate.directive";
import {SortDirective} from "./sort/sort.directive";
import {ItemCountComponent} from "./pagination/item-count.component";
import {SortByDirective} from "./sort/sort-by.directive";
import {FormatMediumDatePipe} from "./date/format-medium-date.pipe";
import {FormatMediumDatetimePipe} from "./date/format-medium-datetime.pipe";
import {DurationPipe} from "./date/duration.pipe";
import {StarPipe, StarPipeEtoil} from "./pipes/star.pipe";
import {SelectOptionsTranslatePipe} from "./pipes/select-options-translate.pipe";
import {MargeModePipe} from "./pipes/marge-mode.pipe";
import {ArrangementIconPipe, ArrangementPipe} from "./pipes/arrangement.pipe";
import {CurrencySymbolPipe} from "./currency/currency-symbol-pipe";
import {FindCurrencyFromCodePipe, FindCurrencyNewFromCodePipe} from "./currency/find-currency-from-code.pipe";
import {TouristicServiceCardsComponent} from "../entities/touristic-service-admin/touristic-service-cards.component";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {ExpireSessionDialogComponent} from "./component/expire-session/expire-session-dialog.component";
import {HasDealAuthorityDirective} from "./auth/has-deal-authority.directive";
import {CardNewElementComponent} from "./card/new-element/card-new-element.component";
import {NavBarRegisterComponent} from "../account/navbar/nav-bar-register.component";
import {ActiveMenuDirective} from "./navbar/active-menu.directive";
import {CustomToolTipComponent} from "./component/custom-tool-tip/custom-tool-tip.component";
import {ToolTipRendererDirective} from "./component/custom-tool-tip/tool-tip-renderer.directive";
import {FindCurrencyFromKeyPipe, FindFlagFromCurrencyKey} from "./pipes/currency-from-key.pipe";
import {AgmCoreModule} from "@agm/core";
import {MatGoogleMapsAutocompleteModule} from "@angular-material-extensions/google-maps-autocomplete";
import {NgxMatIntlTelInputComponent, NgxMatIntlTelInputModule} from "ngx-mat-intl-tel-input";
import {ActivationTierDialogComponent} from "./component/tier-valide-popup/activation-dialog.component";
import {TierCommentDialogComponent} from "./component/tier-comment-popup/comment-dialog.component";

// import { InfiniteScrollModule } from 'ngx-infinite-scroll';


@NgModule({
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    TranslateModule,
    MaterialModule,
    AgmCoreModule,
    MatGoogleMapsAutocompleteModule,
    // InfiniteScrollModule,
    PerfectScrollbarModule,
    NgxMatIntlTelInputModule,

    FooterComponent,
    NavbarComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    CustomizerComponent,
    NotificationSidebarComponent,
    ExpireSessionDialogComponent,
    CardNewElementComponent,
    ToggleFullscreenDirective,
    SidebarDirective,
    TopMenuDirective,
    TouristicServiceCardsComponent,
    DeleteDialogComponent,
    ActivationTierDialogComponent,
    TierCommentDialogComponent,
    ActivationDialogComponent,
    NavBarRegisterComponent,
    FindLanguageFromKeyPipe,
    FindCurrencyFromKeyPipe,
    FindFlagFromCurrencyKey,
    FindFlagFromKeyPipe,
    TranslateDirective,
    AlertComponent,
    AlertErrorComponent,
    HasAnyAuthorityDirective,
    HasDealAuthorityDirective,
    ActiveMenuDirective,
    ToolTipRendererDirective,

    DurationPipe,
    FormatMediumDatetimePipe,
    FormatMediumDatePipe,
    SortByDirective,
    SortDirective,
    ItemCountComponent,
    StarPipe,
    StarPipeEtoil,
    SelectOptionsTranslatePipe,
    MargeModePipe,
    ArrangementPipe,
    ArrangementIconPipe,
    CurrencySymbolPipe,
    FindCurrencyFromCodePipe,
    FindCurrencyNewFromCodePipe,
    CustomToolTipComponent,
  ],
  imports: [
    FormsModule, CommonModule, NgbModule, InfiniteScrollModule, FontAwesomeModule, ReactiveFormsModule, TranslateModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC2uqTBQmvPFZ6XB5nYTxsFUKKSUM57QaE',
      libraries: ['places', 'geometry', 'directions'],
    }),
    MatGoogleMapsAutocompleteModule,
    RouterModule,
    CommonModule,
    NgbModule,
    TranslateModule,
    FormsModule,
    OverlayModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    ClickOutsideModule,
    AutocompleteModule,
    PipeModule,
    NgxMatIntlTelInputModule,
    MaterialModule
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    CustomizerComponent,
    NotificationSidebarComponent,
    TouristicServiceCardsComponent,
    ExpireSessionDialogComponent,
    CardNewElementComponent,
    NavBarRegisterComponent,

    ToggleFullscreenDirective,
    SidebarLinkDirective,
    SidebarDropdownDirective,
    SidebarAnchorToggleDirective,
    SidebarDirective,
    TopMenuLinkDirective,
    TopMenuDropdownDirective,
    TopMenuAnchorToggleDirective,
    TopMenuDirective,
    ActiveMenuDirective,
    ToolTipRendererDirective,

    DeleteDialogComponent,
    ActivationDialogComponent,
    ActivationTierDialogComponent,
    TierCommentDialogComponent,
    FindLanguageFromKeyPipe,
    FindCurrencyFromKeyPipe,
    FindFlagFromCurrencyKey,
    FindFlagFromKeyPipe,
    TranslateDirective,
    AlertComponent,
    AlertErrorComponent,
    CustomToolTipComponent,
    HasAnyAuthorityDirective,
    HasDealAuthorityDirective,
    DurationPipe,
    FormatMediumDatetimePipe,
    FormatMediumDatePipe,
    SortByDirective,
    SortDirective,
    ItemCountComponent,
    StarPipe,
    StarPipeEtoil,
    SelectOptionsTranslatePipe,
    MargeModePipe,
    ArrangementPipe,
    ArrangementIconPipe,
    CurrencySymbolPipe,
    FindCurrencyFromCodePipe,
    FindCurrencyNewFromCodePipe,
  ]
})
export class SharedModule {
}
