import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

import {EventManager, EventWithContent} from 'app/core/util/event-manager.service';
import {ExpireSessionDialogComponent} from "../../shared/component/expire-session/expire-session-dialog.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private eventManager: EventManager,    protected modalService: NgbModal
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        error: (err: HttpErrorResponse) => {
          console.log("err",err);
          if (err.status === 500 && err.url &&  err.error.details==='Full authentication is required to access this resource') {
            //  "detail" : "Full authentication is required to access this resource",
            // const modalRef = this.modalService.open(ExpireSessionDialogComponent, {size: 'sm', backdrop: 'static'});
          }
         else if (err.status === 401 && err.url && err.url.includes('api/account') ) {
            // const modalRef = this.modalService.open(ExpireSessionDialogComponent, {size: 'lg', backdrop: 'static'});
            // this.loginService.login();
          }
          else if (!(err.status === 401 && (err.message === '' || err.url?.includes('api/account')))) {
            this.eventManager.broadcast(new EventWithContent('dealwebbackApp.httpError', err));
          }else {
            this.eventManager.broadcast(new EventWithContent('dealwebbackApp.httpError', err));
          }
        },
      })
    );
  }
}
