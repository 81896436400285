import {Component, OnInit} from '@angular/core';

import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs';
import {HttpResponse} from '@angular/common/http';
import {ModalTypeEnum} from 'app/shared/model/modal-type.enum';
import {Router} from '@angular/router';
import {EventManager} from "../../../core/util/event-manager.service";

@Component({
  selector: 'deal-activate-dialog',
  templateUrl: './activate-dialog.component.html',
})
export class ActivationDialogComponent implements OnInit {
  entityListModification?: string;
  modalType?: ModalTypeEnum;
  elementName?: string;
  element?: string;
  entityService?: Observable<HttpResponse<any>>;

  public title?: string;
  public commentaire?: boolean;
  public body?: string;
  public action = 'entity.action.save';
  public showbutton?: boolean;
  public comment?: string;

  constructor(public activeModal: NgbActiveModal, private eventManager: EventManager, private router: Router) {
  }

  clear() {
    this.activeModal.dismiss('cancel');
  }

  confirmValidate() {
    this.entityService!.subscribe(response => {
      this.eventManager.broadcast({
        name: this.entityListModification || '',
        content: response.body,
      });
      this.activeModal.dismiss(true);
    });
  }

  ngOnInit(): void {
    this.showbutton = true;
    if (this.modalType === ModalTypeEnum.ACTIVATION) {
      this.title = 'Confirmation Activation';
      this.body = 'global.activation';
      this.action = 'entity.action.activate';
    } else if (this.modalType === ModalTypeEnum.DEACTIVATION) {
      this.title = 'Confirmation Deactivation';
      this.body = 'global.deactivation';
      this.action = 'entity.action.deactivate';
    } else if (this.modalType === ModalTypeEnum.IMPROVE) {
      this.title = 'Confirmation Improvement';
      this.body = 'global.improvement';
    } else if (this.modalType === ModalTypeEnum.REJECT) {
      this.title = 'Confirmation Reject';
      this.commentaire = true;
      this.body = 'global.disimprovement';
    } else if (this.modalType === ModalTypeEnum.COMPLETE) {
      this.title = 'Confirmation Complete';
      this.body = 'global.complete';
    } else if (this.modalType === ModalTypeEnum.DELETE) {
      this.title = 'Confirmation Delete';
      this.body = 'global.delete-draft';
    } else if (this.modalType === ModalTypeEnum.REGISTER) {
      this.title = 'Login';
      this.body = 'global.register';
      this.showbutton = false;
    }
  }

  gotToRegister() {
    this.activeModal.dismiss('cancel');
    this.router.navigate(['/register/client']);
  }

  gotToLogin() {
    this.activeModal.dismiss('cancel');
    this.router.navigate(['/login']);
  }
}
