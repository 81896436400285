export enum ModalTypeEnum {
  ACTIVATION,
  DEACTIVATION,
  IMPROVE,
  REJECT,
  COMPLETE,
  DELETE,
  REGISTER,
  DENIED,
  PENDING,
  BLACK_LIST,
}
