import {Pipe, PipeTransform} from '@angular/core';
import {CLASSES} from 'app/shared/constants/class.constants';

@Pipe({name: 'fromStar'})
export class StarPipe implements PipeTransform {
  private stars: any[] = CLASSES;

  transform(star: string): string {
    return this.stars.filter(s => s.id === star)[0]?.itemName;
  }
}

@Pipe({name: 'fromStarEtoil'})
export class StarPipeEtoil implements PipeTransform {
  private stars: any[] = CLASSES;

  transform(star: string | undefined): string {
    return this.stars.filter(s => s.id === star)[0]?.nbr;
  }
}
