import {Component, OnInit} from '@angular/core';

import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {EventManager} from "../../../core/util/event-manager.service";
import {TierService} from "../../services/tier.service";

@Component({
  selector: 'deal-tier-activate-dialog',
  templateUrl: './activate-dialog.component.html',
})
export class ActivationTierDialogComponent implements OnInit {

  tierPublicId?: string;
  status?: string;
  tierCategory?: string;
  isSave?: boolean;

  public comment?: string;

  constructor(public activeModal: NgbActiveModal, private eventManager: EventManager, private router: Router,
              public tierService: TierService) {
  }

  clear() {
    this.activeModal.dismiss('cancel');
  }

  confirmChange(status: string) {
    this.isSave = true;
    this.tierService.changeStatus(this.tierPublicId, status, this.comment).subscribe(response => {
      this.eventManager.broadcast({
        name: 'tierListModification',
        content: response.body,
      });
      this.activeModal.dismiss(true);
    }, () => {
      this.isSave = false;
    });
  }

  ngOnInit(): void {

  }
}
