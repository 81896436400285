import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {SERVER_API_URL} from 'app/app.constants';
import {MatDialog} from '@angular/material/dialog';

@Injectable({providedIn: 'root'})
export class AuthServerProvider {
  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private $localStorage: LocalStorageService,
    private $sessionStorage: SessionStorageService
  ) {
  }

  getToken() {
    return this.$localStorage.retrieve('authenticationToken') || this.$sessionStorage.retrieve('authenticationToken');
  }

  getProfileData() {
    return this.$localStorage.retrieve('profileData') || this.$sessionStorage.retrieve('profileData');
  }

  getTierPublicId() {
    return this.$localStorage.retrieve('tierPublicId') || this.$sessionStorage.retrieve('tierPublicId');
  }

  getUserPublicId() {
    return this.$localStorage.retrieve('userPublicId') || this.$sessionStorage.retrieve('userPublicId');
  }

  getEmail() {
    return this.$localStorage.retrieve('email') || this.$sessionStorage.retrieve('email');
  }

  getUserPhoto() {
    return this.$localStorage.retrieve('userPhoto') || this.$sessionStorage.retrieve('userPhoto');
  }

  getUserPhotoContentType() {
    return this.$localStorage.retrieve('userPhotoContentType') || this.$sessionStorage.retrieve('userPhotoContentType');
  }

  getComplete() {
    return this.$localStorage.retrieve('complete') || this.$sessionStorage.retrieve('complete');
  }

  login(credentials: any): Observable<any> {
    const data = {
      username: credentials.username,
      email: credentials.email,
      password: credentials.password,
      rememberMe: credentials.rememberMe,
    };

    // function authenticateSuccess(resp) {
    //   const bearerToken = resp.headers.get('Authorization');
    //   if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
    //     const jwt = bearerToken.slice(7, bearerToken.length);
    //     this.storeAuthenticationToken(jwt, credentials.rememberMe);
    //     return jwt;
    //   }
    // }

    return this.http.post(SERVER_API_URL + 'auth/login', data, {observe: 'response'});
    // .pipe(map(authenticateSuccess.bind(this)));
  }

  loginWithToken(jwt: string, rememberMe: boolean) {
    if (jwt) {
      this.storeAuthenticationToken(jwt, rememberMe);
      return Promise.resolve(jwt);
    } else {
      return Promise.reject('auth-jwt-service Promise disimprove'); // Put appropriate error message here
    }
  }

  storeAuthenticationToken(jwt: string, rememberMe: boolean) {
    if (rememberMe) {
      this.$localStorage.store('authenticationToken', jwt);
    } else {
      this.$sessionStorage.store('authenticationToken', jwt);
    }
  }

  storeProfileData(account: any, rememberMe: boolean) {
    if (rememberMe) {
      this.$localStorage.store('profileData', account);
    } else {
      this.$sessionStorage.store('profileData', account);
    }
  }

  storeEmail(email: any, rememberMe: boolean) {
    if (rememberMe) {
      this.$localStorage.store('email', email);
    } else {
      this.$sessionStorage.store('email', email);
    }
  }

  storeUserPhoto(userPhoto: any, userPhotoContentType: any, rememberMe: boolean) {
    if (rememberMe) {
      this.$localStorage.store('userPhotoContentType', userPhotoContentType);
      this.$localStorage.store('userPhoto', userPhoto);
    } else {
      this.$sessionStorage.store('userPhotoContentType', userPhotoContentType);
      this.$sessionStorage.store('userPhoto', userPhoto);
    }
  }

  storeTierPublicId(tierPublicId: string, rememberMe: boolean) {
    if (rememberMe) {
      this.$localStorage.store('tierPublicId', tierPublicId);
    } else {
      this.$sessionStorage.store('tierPublicId', tierPublicId);
    }
  }

  storeUserPublicId(userPublicId: string, rememberMe: boolean) {
    if (rememberMe) {
      this.$localStorage.store('userPublicId', userPublicId);
    } else {
      this.$sessionStorage.store('userPublicId', userPublicId);
    }
  }

  storeRolePublicId(rolePublicId: string, rememberMe: boolean) {
    if (rememberMe) {
      this.$localStorage.store('rolePublicId', rolePublicId);
    } else {
      this.$sessionStorage.store('rolePublicId', rolePublicId);
    }
  }

  logout(): Observable<any> {
    return new Observable(observer => {
      this.$localStorage.clear('authenticationToken');
      this.$localStorage.clear('email');
      this.$localStorage.clear('-userPhoto');
      this.$localStorage.clear('--userPhotoContentType');
      this.$localStorage.clear('---userPublicId');
      this.$localStorage.clear('profileData');
      this.$localStorage.clear('tierPublicId');
      this.$localStorage.clear('userPublicId');
      this.$localStorage.clear('rolePublicId');
      this.$localStorage.clear('complete');
      this.$sessionStorage.clear('email');
      this.$sessionStorage.clear('authenticationToken');
      this.$sessionStorage.clear('profileData');
      this.$sessionStorage.clear('tierPublicId');
      this.$sessionStorage.clear('userPublicId');
      this.$sessionStorage.clear('rolePublicId');
      this.$sessionStorage.clear('complete');
      this.$sessionStorage.clear('-userPhoto');
      this.$sessionStorage.clear('--userPhotoContentType');
      this.$sessionStorage.clear('---userPublicId');
      observer.complete();
    });
  }

  // loginWithPopup() {
  //   this.dialog.open(LoginDialogComponent, {});
  // }
}
