 import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

import {FullLayoutComponent} from "./layouts/full/full-layout.component";

import {Full_ROUTES} from "./shared/routes/full-layout.routes";

import {AuthGuard} from './shared/auth/auth-guard.service';

const appRoutes: Routes = [
  {
    path: 'activate',
    loadChildren: () => import('./account/activate/activate.module').then(m => m.ActivateModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./account/register/register.module').then(m => m.RegisterModule),
  },
  // {
  //   path: 'login',
  //   loadChildren: () => import('./account/login/login.module').then(m => m.LoginModule),
  // },
  {
    path: '',
    component: FullLayoutComponent,
    data: {title: 'full Views'},
    children: Full_ROUTES,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: 'pages/error'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
