import {Injectable} from '@angular/core';
import {Location} from '@angular/common';

import {Logout} from './logout.model';
import {KeycloakService} from 'keycloak-angular';
import {Router, RouterStateSnapshot} from '@angular/router';
import {SERVER_SECURITY_API_URL} from "../app.constants";
import {HttpClient} from "@angular/common/http";
// import {flatMap} from "rxjs/operators";
import {AccountService} from "../core/auth/account.service";
import {AuthServerProvider} from "../core/auth/auth-jwt.service";
import {flatMap} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class LoginService {
  state: RouterStateSnapshot;

  constructor(private location: Location,
              private authServerProvider: AuthServerProvider,
              private accountService: AccountService,
              private http: HttpClient,
              private router: Router,
              protected readonly keycloak: KeycloakService) {
    this.state = this.router.routerState.snapshot;
  }



  login(): void {
    // If you have configured multiple OIDC providers, then, you can update this URL to /login.
    // It will show a Spring Security generated login page with links to configured OIDC providers.
    // location.href = `${location.origin}${this.location.prepareExternalUrl('oauth2/authorization/oidc')}`;

    this.keycloak.login({
      redirectUri: window.location.origin + this.state.url,
    });

  }

  logout(): void {
    this.keycloak.logout().then();
    this.authServerProvider.logout().subscribe(()=>{});
    // this.authServerProvider.logout().subscribe((logout: Logout) => {
    //   let logoutUrl = logout.logoutUrl;
    //   const redirectUri = `${location.origin}${this.location.prepareExternalUrl('/')}`;
    //
    //   // if Keycloak, uri has protocol/openid-connect/token
    //   if (logoutUrl.includes('/protocol')) {
    //     logoutUrl = logoutUrl + '?redirect_uri=' + redirectUri;
    //   } else {
    //     // Okta
    //     logoutUrl = logoutUrl + '?id_token_hint=' + logout.idToken + '&post_logout_redirect_uri=' + redirectUri;
    //   }
    //   window.location.href = logoutUrl;
    // });
  }
}
