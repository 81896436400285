import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'deal-card-new-element',
  styleUrls: ['../card.scss'],
  templateUrl: 'card-new-element.component.html',
})
export class CardNewElementComponent {
  @Output() action = new EventEmitter();
  @Input() icon: any;
}
